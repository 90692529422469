
.header__navigation ul ul li:not(.has-child) > a.no-target {
  &:after {
    background: none;
    opacity: 0.8;
    cursor: not-allowed;
  }
  &:hover {
    background: none;
    color: #002E5E;
    cursor: not-allowed;
  }
}
