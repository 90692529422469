/**
 * how-learn.scss
 */
.section__how-learn {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  h2 {
    font-size: 3.2rem;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  p {
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 1.75;
    }
  }

  .notice_blue {
    @media (max-width: 768px) {
      margin-bottom: 48px;
    }
  }

  &-description {
    font-size: 1.8rem;
    line-height: 1.75;

    &-title {
      @media (max-width: 768px) {
        margin-top: 60px;
      }

      @media (max-width: 540px) {
        margin-top: 0;
      }
    }
  }

  &-img {
    height: 67rem;
    position: relative;
    z-index: 1;
  }

  &-begin {
    .section__subsection-wrapper {
      padding: 13rem 0 14rem;
      margin-top: -19rem;

      @media (max-width: 768px) {
        margin-top: -24rem;
      }

      @media (max-width: 540px) {
        padding-top: 26rem;
      }
    }

    h2 {
      color: $white;
    }

    .notice__content,
    .item-content {
      color: $white;
    }

    a {
      text-decoration: underline;
      color: $white;
    }

    &-items {
      svg {
        width: 7.2rem;
        height: 7.2rem;

        @media (max-width: 768px) {
          width: 48px;
          height: 48px;
        }
      }
    }

    &-item {
      @media (max-width: 768px) {
        margin-bottom: 50px;

        &:last-child {
          margin-bottom: 20px;
        }
      }

      h2 a {
        color: $white;
        text-decoration: none;
        align-items: center;
        padding-right: 3.2rem;
        background-size: 2.4rem auto;
        background-repeat: no-repeat;
        transition: .3s ease-in-out;
        background-position: 100% 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='circle' d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill='transparent'/%3E%3Cpath class='arrow' d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z' fill='%23#{str-slice('#{$white}', 2)}'/%3E%3C/svg%3E");

        @media (max-width: 768px) {
          padding-right: 32px;
          background-size: 24px auto;
        }

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath class='circle' d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill='%23#{str-slice('#{$red}', 2)}'/%3E%3Cpath class='arrow' d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z' fill='%23#{str-slice('#{$white}', 2)}'/%3E%3C/svg%3E");
        }
      }
    }

    .item-content {
      font-size: 1.8rem;
      line-height: 1.75;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (min-width: 769px) {
        &_custom {
          max-width: 38.4rem;
        }
      }
    }
  }

  &-basic {
    padding: 12rem 0 10rem;

    p {
      font-size: 1.8rem;
      line-height: 1.75;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    img {
      display: block;
      max-width: 100%;

      @media (max-width: 768px) {
        max-width: 50%;
        margin-top: 20px;
      }

      @media (max-width: 540px) {
        max-width: 100%;
      }
    }

    .row {
      @media (max-width: 768px) {
        margin-top: 80px !important;
      }
    }
  }

  &-faq {
    .section__subsection-wrapper {
      padding: 13rem 0 15rem;
    }

    h2 {
      color: $white;
    }

    &-item {
      padding-bottom: 2.4rem;

      @media (max-width: 768px) {
        padding-bottom: 24px;
      }

      @media (max-width: 540px) {
        padding-bottom: 16px;
      }
    }


    &-question {
      display: flex;
      flex-direction: column;
    }

    &-question &-content {
      max-width: 60%;

      @media (max-width: 540px) {
        max-width: 80%;
      }

      &:before {
        left: 2rem;

        @media (max-width: 768px) {
          left: 20px;
        }
      }
    }

    &-question &-logo {
      background-image: url('/pic/avatar-small.png');
    }

    &-answer &-content {
      max-width: 75%;

      @media (max-width: 768px) {
        max-width: calc(100% - 100px);
        padding-bottom: 24px;
        padding-top: 24px;
      }

      @media (max-width: 540px) {
        max-width: calc(100% - 64px);
      }

      &:before {
        right: 2rem;

        @media (max-width: 768px) {
          right: 20px;
        }
      }
    }

    &-answer &-logo {
      background-image: url('/pic/avatar-logo.png');
    }

    &-answer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: -4.8rem;

      @media (max-width: 768px) {
        margin-top: -48px;
      }
    }

    &-content {
      padding: 1.2rem 2.6rem;
      position: relative;
      border-radius: 1.8rem;
      background-color: $white;
      font-size: 1.8rem;
      line-height: 1.33;
      margin-bottom: 2rem;

      @media (max-width: 768px) {
        padding: 12px 26px;
        border-radius: 18px;
        font-size: 18px;
        margin-bottom: 20px;
      }

      @media (max-width: 540px) {
        font-size: 16px;
        margin-bottom: 16px;
      }

      &:before {
        width: 6px;
        height: 6px;
        background-color: $white;
        content: '';
        position: absolute;
        transform: rotate(45deg);
        bottom: -3.5px;
      }
    }

    &-logo {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 2rem;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: $white;

      @media (max-width: 768px) {
        width: 48px;
        height: 48px;
        border-radius: 20px;
      }

      @media (max-width: 540px) {
        width: 44px;
        height: 44px;
      }
    }

    a {
      color: $red;
      text-decoration: underline;
    }
  }

  &-accept {
    padding: 12rem 0 15rem;

    @media (max-width: 540px) {
      .col-auto.mb-24 {
        margin-bottom: 10px;
      }
    }

    .container-grid__item {
      flex: 0 0 25%;
      max-width: 25%;

      @media (max-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (max-width: 540px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      svg {
        margin: 5.6rem 0 2.4rem;
        width: 7.2rem;
        height: 7.2rem;
        display: block;

        @media (max-width: 768px) {
          margin: 56px 0 24px;
          width: 48px;
          height: 48px;
        }
      }

      h2 {
        display: inline-block;
        padding-right: 2.4rem;
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: 1.2rem auto;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.92 6.61994C11.8724 6.49719 11.801 6.38505 11.71 6.28994L6.71 1.28994C6.61676 1.1967 6.50607 1.12274 6.38425 1.07228C6.26243 1.02182 6.13186 0.99585 6 0.99585C5.7337 0.99585 5.4783 1.10164 5.29 1.28994C5.19676 1.38318 5.1228 1.49387 5.07234 1.61569C5.02188 1.73751 4.99591 1.86808 4.99591 1.99994C4.99591 2.26624 5.1017 2.52164 5.29 2.70994L8.59 5.99994H1C0.734784 5.99994 0.48043 6.1053 0.292893 6.29283C0.105357 6.48037 0 6.73472 0 6.99994C0 7.26516 0.105357 7.51951 0.292893 7.70705C0.48043 7.89458 0.734784 7.99994 1 7.99994H8.59L5.29 11.2899C5.19627 11.3829 5.12188 11.4935 5.07111 11.6154C5.02034 11.7372 4.9942 11.8679 4.9942 11.9999C4.9942 12.132 5.02034 12.2627 5.07111 12.3845C5.12188 12.5064 5.19627 12.617 5.29 12.7099C5.38296 12.8037 5.49356 12.8781 5.61542 12.9288C5.73728 12.9796 5.86799 13.0057 6 13.0057C6.13201 13.0057 6.26272 12.9796 6.38458 12.9288C6.50644 12.8781 6.61704 12.8037 6.71 12.7099L11.71 7.70994C11.801 7.61484 11.8724 7.50269 11.92 7.37994C12.02 7.13648 12.02 6.8634 11.92 6.61994Z' fill='%23333333'/%3E%3C/svg%3E ");

        @media (max-width: 768px) {
          background-size: 12px auto;
          padding-right: 24px;
          margin-bottom: 12px;
        }
      }

      a {
        color: $red;
        text-decoration: underline;
      }

      .item-content {
        font-size: 1.8rem;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }

      &:first-child {

        h2 {
          background-size: 2rem auto;
          padding-right: 2.8rem;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16C1.26522 16 1.51957 15.8946 1.70711 15.7071C1.89464 15.5196 2 15.2652 2 15V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0ZM19.92 7.62C19.8724 7.49725 19.801 7.3851 19.71 7.29L15.71 3.29C15.6168 3.19676 15.5061 3.1228 15.3842 3.07234C15.2624 3.02188 15.1319 2.99591 15 2.99591C14.7337 2.99591 14.4783 3.1017 14.29 3.29C14.1017 3.4783 13.9959 3.7337 13.9959 4C13.9959 4.2663 14.1017 4.5217 14.29 4.71L16.59 7H5C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8C4 8.26522 4.10536 8.51957 4.29289 8.70711C4.48043 8.89464 4.73478 9 5 9H16.59L14.29 11.29C14.1963 11.383 14.1219 11.4936 14.0711 11.6154C14.0203 11.7373 13.9942 11.868 13.9942 12C13.9942 12.132 14.0203 12.2627 14.0711 12.3846C14.1219 12.5064 14.1963 12.617 14.29 12.71C14.383 12.8037 14.4936 12.8781 14.6154 12.9289C14.7373 12.9797 14.868 13.0058 15 13.0058C15.132 13.0058 15.2627 12.9797 15.3846 12.9289C15.5064 12.8781 15.617 12.8037 15.71 12.71L19.71 8.71C19.801 8.6149 19.8724 8.50275 19.92 8.38C20.02 8.13654 20.02 7.86346 19.92 7.62Z' fill='%23333333'/%3E%3C/svg%3E ");
        }
      }

      &:last-child {

        h2 {
          background-size: 2rem auto;
          padding-right: 2.8rem;
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.71 7.29L11.71 3.29C11.6168 3.19676 11.5061 3.1228 11.3842 3.07234C11.2624 3.02188 11.1319 2.99591 11 2.99591C10.8681 2.99591 10.7376 3.02188 10.6158 3.07234C10.4939 3.1228 10.3832 3.19676 10.29 3.29C10.1968 3.38324 10.1228 3.49393 10.0723 3.61575C10.0219 3.73757 9.99591 3.86814 9.99591 4C9.99591 4.13186 10.0219 4.26243 10.0723 4.38425C10.1228 4.50607 10.1968 4.61676 10.29 4.71L12.59 7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H12.59L10.29 11.29C10.1963 11.383 10.1219 11.4936 10.0711 11.6154C10.0203 11.7373 9.9942 11.868 9.9942 12C9.9942 12.132 10.0203 12.2627 10.0711 12.3846C10.1219 12.5064 10.1963 12.617 10.29 12.71C10.383 12.8037 10.4936 12.8781 10.6154 12.9289C10.7373 12.9797 10.868 13.0058 11 13.0058C11.132 13.0058 11.2627 12.9797 11.3846 12.9289C11.5064 12.8781 11.617 12.8037 11.71 12.71L15.71 8.71C15.801 8.6149 15.8724 8.50275 15.92 8.38C16.02 8.13654 16.02 7.86346 15.92 7.62C15.8724 7.49725 15.801 7.3851 15.71 7.29ZM19 0C18.7348 0 18.4804 0.105357 18.2929 0.292893C18.1054 0.48043 18 0.734784 18 1V15C18 15.2652 18.1054 15.5196 18.2929 15.7071C18.4804 15.8946 18.7348 16 19 16C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0Z' fill='%23333333'/%3E%3C/svg%3E ");
        }
      }
    }
  }

  &-distance {

    h2 {
      color: $white;
    }

    .link-target {
      color: $white;

      @media (max-width: 768px) {
        &:before {
          background-color: $blue-02;
        }
      }
    }

    .section__subsection-wrapper {
      padding: 13rem 0 12rem;

      .container-grid__item {
        flex: 0 0 25%;
        max-width: 25%;

        @media (max-width: 768px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media (max-width: 540px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        svg {
          margin: 5.6rem 0 2.4rem;
          width: 7.2rem;
          height: 7.2rem;
          display: block;

          @media (max-width: 768px) {
            margin: 56px 0 24px;
            width: 48px;
            height: 48px;
          }
        }

        h2 {
          display: inline-block;
          color: $text;
          padding-right: 2.4rem;
          background-repeat: no-repeat;
          background-position: 100% 50%;
          background-size: 1.2rem auto;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.92 6.61994C11.8724 6.49719 11.801 6.38505 11.71 6.28994L6.71 1.28994C6.61676 1.1967 6.50607 1.12274 6.38425 1.07228C6.26243 1.02182 6.13186 0.99585 6 0.99585C5.7337 0.99585 5.4783 1.10164 5.29 1.28994C5.19676 1.38318 5.1228 1.49387 5.07234 1.61569C5.02188 1.73751 4.99591 1.86808 4.99591 1.99994C4.99591 2.26624 5.1017 2.52164 5.29 2.70994L8.59 5.99994H1C0.734784 5.99994 0.48043 6.1053 0.292893 6.29283C0.105357 6.48037 0 6.73472 0 6.99994C0 7.26516 0.105357 7.51951 0.292893 7.70705C0.48043 7.89458 0.734784 7.99994 1 7.99994H8.59L5.29 11.2899C5.19627 11.3829 5.12188 11.4935 5.07111 11.6154C5.02034 11.7372 4.9942 11.8679 4.9942 11.9999C4.9942 12.132 5.02034 12.2627 5.07111 12.3845C5.12188 12.5064 5.19627 12.617 5.29 12.7099C5.38296 12.8037 5.49356 12.8781 5.61542 12.9288C5.73728 12.9796 5.86799 13.0057 6 13.0057C6.13201 13.0057 6.26272 12.9796 6.38458 12.9288C6.50644 12.8781 6.61704 12.8037 6.71 12.7099L11.71 7.70994C11.801 7.61484 11.8724 7.50269 11.92 7.37994C12.02 7.13648 12.02 6.8634 11.92 6.61994Z' fill='%23333333'/%3E%3C/svg%3E ");

          @media (max-width: 768px) {
            padding-right: 24px;
            background-size: 12px auto;
            margin-bottom: 12px;
          }
        }

        a {
          color: $red;
          text-decoration: underline;
        }

        .item-content {
          font-size: 1.8rem;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }

        &:first-child {

          h2 {
            background-size: 2rem auto;
            padding-right: 2.8rem;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16C1.26522 16 1.51957 15.8946 1.70711 15.7071C1.89464 15.5196 2 15.2652 2 15V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0ZM19.92 7.62C19.8724 7.49725 19.801 7.3851 19.71 7.29L15.71 3.29C15.6168 3.19676 15.5061 3.1228 15.3842 3.07234C15.2624 3.02188 15.1319 2.99591 15 2.99591C14.7337 2.99591 14.4783 3.1017 14.29 3.29C14.1017 3.4783 13.9959 3.7337 13.9959 4C13.9959 4.2663 14.1017 4.5217 14.29 4.71L16.59 7H5C4.73478 7 4.48043 7.10536 4.29289 7.29289C4.10536 7.48043 4 7.73478 4 8C4 8.26522 4.10536 8.51957 4.29289 8.70711C4.48043 8.89464 4.73478 9 5 9H16.59L14.29 11.29C14.1963 11.383 14.1219 11.4936 14.0711 11.6154C14.0203 11.7373 13.9942 11.868 13.9942 12C13.9942 12.132 14.0203 12.2627 14.0711 12.3846C14.1219 12.5064 14.1963 12.617 14.29 12.71C14.383 12.8037 14.4936 12.8781 14.6154 12.9289C14.7373 12.9797 14.868 13.0058 15 13.0058C15.132 13.0058 15.2627 12.9797 15.3846 12.9289C15.5064 12.8781 15.617 12.8037 15.71 12.71L19.71 8.71C19.801 8.6149 19.8724 8.50275 19.92 8.38C20.02 8.13654 20.02 7.86346 19.92 7.62Z' fill='%23333333'/%3E%3C/svg%3E ");

            @media (max-width: 768px) {
              background-size: 20px auto;
              padding-right: 28px;
            }
          }
        }

        &:last-child {

          h2 {
            background-size: 2rem auto;
            padding-right: 2.8rem;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.71 7.29L11.71 3.29C11.6168 3.19676 11.5061 3.1228 11.3842 3.07234C11.2624 3.02188 11.1319 2.99591 11 2.99591C10.8681 2.99591 10.7376 3.02188 10.6158 3.07234C10.4939 3.1228 10.3832 3.19676 10.29 3.29C10.1968 3.38324 10.1228 3.49393 10.0723 3.61575C10.0219 3.73757 9.99591 3.86814 9.99591 4C9.99591 4.13186 10.0219 4.26243 10.0723 4.38425C10.1228 4.50607 10.1968 4.61676 10.29 4.71L12.59 7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H12.59L10.29 11.29C10.1963 11.383 10.1219 11.4936 10.0711 11.6154C10.0203 11.7373 9.9942 11.868 9.9942 12C9.9942 12.132 10.0203 12.2627 10.0711 12.3846C10.1219 12.5064 10.1963 12.617 10.29 12.71C10.383 12.8037 10.4936 12.8781 10.6154 12.9289C10.7373 12.9797 10.868 13.0058 11 13.0058C11.132 13.0058 11.2627 12.9797 11.3846 12.9289C11.5064 12.8781 11.617 12.8037 11.71 12.71L15.71 8.71C15.801 8.6149 15.8724 8.50275 15.92 8.38C16.02 8.13654 16.02 7.86346 15.92 7.62C15.8724 7.49725 15.801 7.3851 15.71 7.29ZM19 0C18.7348 0 18.4804 0.105357 18.2929 0.292893C18.1054 0.48043 18 0.734784 18 1V15C18 15.2652 18.1054 15.5196 18.2929 15.7071C18.4804 15.8946 18.7348 16 19 16C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0Z' fill='%23333333'/%3E%3C/svg%3E ");

            @media (max-width: 768px) {
              background-size: 20px auto;
              padding-right: 28px;
            }
          }
        }
      }
    }
  }

  &-possible {
    padding: 12rem 0;

    a {
      color: $red;
      text-decoration: underline;
    }

    p {
      font-size: 1.8rem;
      line-height: 1.75;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}