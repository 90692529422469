@import '../../assets/style/variables';
.test {
  &__header {
    padding: 3rem 0 4.8rem;
    &-course {
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $grey-05;
      margin-bottom: 1.2rem;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 32px;
      }
    }
    &-title {
      font-size: 2.4rem;
      line-height: 2.4rem;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  &__info {
    border-top: .2rem solid $disabled;
    padding-top: 6.4rem;
    font-size: 1.8rem;
    line-height: 3.2rem;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
    &-item {
      font-size: 2.4rem;
      line-height: 2.4rem;
      background: $grey-bg;
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 24px;
      }
      &-desc {
        padding: 2.4rem;
        @media (max-width: 768px) {
          padding: 12px;
        }
      }
      &-text {
        font-size: 1.8rem;
        line-height: 3.2rem;
        padding: 1.2rem 0 3.2rem;
      }
    }
    &-title-success {
      font-size: 3.2rem;
      line-height: 3.6rem;
      color: $green-02;
    }
  }
  &__pager {
    display: flex;
    margin-bottom: 4.8rem;
    &__item {
      width: 5.8rem;
      height: 4.8rem;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-bottom: .2rem solid $disabled;
      color: $disabled;
      padding: 0;
      &-current,
      &:hover {
        color: $blue-03;
        border-color: $blue-03;
        background: $grey-bg;
        &.text-danger {
          background-color: rgba(237, 27, 47, 0.2)!important;
        }
        &.text-success {
          background-color: rgba(39, 174, 96, 0.2)!important;
        }
      }
      &-complete {
        color: $primary;
        border-color: $primary;
      }
      &.text-danger {
        border-color: $red;
      }
      &.text-success {
        border-color: $green-02;
      }
    }
  }
  .form-checkbox__wrapper {
    display: flex!important;
  }
  &__item {
    &-title {
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 3.5rem;
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 18px;
      }
    }
    &-question {
      font-size: 2.4rem;
      line-height: 4.8rem;
      padding: 0;
      &-item {
        display: block;
        padding-left: 4.8rem;
        label {
          cursor: pointer;
          padding: 2rem 0 2rem 4.8rem!important;
          margin-bottom: 0!important;
          @media (max-width: 768px) {
            padding: 20px 0 20px 48px!important;
          }
          display: block;
        }
        &:has(:checked) {
          background: $grey-06;
          &.incorrect {
            background-color: rgba(237, 27, 47, 0.2);
          }
        }
        &.correct {
          background-color: rgba(39, 174, 96, 0.2);
        }
      }
    }
  }
  &__footer {
    border-top: .2rem solid $disabled;
    padding: 4.8rem 0;
    &-link {
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $blue-03;
      img {
        margin-left: .8rem;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    &-button {
      margin-bottom: 6.4rem;
      @media (max-width: 768px) {
        margin-bottom: 0;
        margin-top: 32px;
      }
    }
  }
}