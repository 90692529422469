/**
 * learn
 */
.section__learn {
  padding-bottom: 0;

  &-grid {
    margin: 0 -2.3rem;
  }

  &-container {
    padding: 0 2.3rem;
    margin-bottom: 4.6rem;
  }

  .container-shadow {
    height: 100%;
    background-color: $white;
  }

  &-description {
    flex: 0 0 43%;
    max-width: 43%;
    margin-bottom: 19px;

    .text {
      font-size: 1.8rem;

      @media (max-width: 768px){
        font-size: 13px;
        line-height: 1.8;
      }
    }
    @media (max-width: 540px){
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &-image {
    flex: 0 0 43%;
    max-width: 43%;

    .container-shadow {
      position: relative;

      .arrow-button {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: .3s ease-in-out;
        width: 4.8rem;
        height: 4.8rem;
        cursor: pointer;
        border-radius: 50%;
        background-color: $red;
        background-position: 50% 50%;
        background-size: 4.8rem auto;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='%23ED1B2F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='white'/%3E%3C/svg%3E");

        &:hover {
          width: 10rem;
          height: 10rem;
          background-size: 2rem auto;
          background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 9L1 1V17L16 9Z' stroke='white' stroke-width='2' stroke-linejoin='bevel'/%3E%3C/svg%3E");
          background-color: $red-hover;
        }
      }
    }

    @media (max-width: 768px){
      flex: 0 0 100%;
      max-width: 100%;
      height: 330px;
    }

    @media (max-width: 540px){
      height: 208px;
    }
  }

  &-statistics {
    flex: 0 0 57%;
    max-width: 57%;

    @media (max-width: 540px){
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &-feedback {
    font-size: 1.8rem;
    flex: 0 0 57%;
    max-width: 57%;

    .section__learn-feedback-text .owl-stage-outer:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 12px;
      background: $white;
      z-index: 100;
    }

    .feedback-wrapper {
      padding-right: 1.5rem;
    }

    .feedback-content {
      max-height: 8.4rem;
      overflow: hidden;
      margin-bottom: 2.8rem;

      p {
        margin: 0;
      }
    }

    @media (max-width: 768px){
      font-size: 13px;
      line-height: 1.8;
      max-height: 100%;

      .feedback-author {
        margin: 25px 0 32px;

        @media (max-width: 495px){
          margin-bottom: 88px;
        }
      }
    }

    @media (max-width: 768px){
      flex: 0 0 100%;
      max-width: 100%;
    }

    .container-shadow {
      height: auto;
      padding: 2.4rem;

      @media (max-width: 768px){
        padding: 24px;
      }
    }

    &-text {

      .owl-nav {
        margin-top: 1.2rem;
        justify-content: flex-end;

        @media (max-width: 768px){
          margin-top: 20px;
        }
      }
    }

    &-users {
      width: 30.4rem;
      margin-top: -4.8rem;
      position: relative;

      @media (max-width: 768px){
        width: 300px;
        margin-top: -41px;
      }

      @media (max-width: 495px){
        width: 100%;
        margin-top: -100px;
        margin-bottom: 45px;
      }

      .avatar {
        cursor: pointer;

        @media (max-width: 768px){
          width: 50px;
          height: 50px;
          border-radius: 20px;
        }
      }

      .user-placeholder {
        width: 5.8rem;
        height: 5.8rem;
        border-radius: 2.4rem;
        box-shadow: 0 0 0 0.2rem $blue-02;
        position: absolute;
        left: 0;
        transform: translate(-0.3rem, -0.3rem);
        transition: .3s linear;

        @media (max-width: 768px){
          width: 54px;
          height: 54px;
          border-radius: 20px;
          transform: translate(-2px, -2px);
        }
      }
    }
  }
}