.section__coaches-item {
  text-decoration: none;
  color: inherit;
}

.section__coaches-carousel {
  .item {
    text-decoration: none;
    color: inherit;
  }
  &-link {
    text-decoration: underline;
    color: $blue-03;
    font-weight: 700;
    margin-top: 2.4rem;
    font-size: 1.8rem;
  }
}

.partners {
  height: 122px;
  .reu {
    img {
      max-height: 100%;
    }
  }
}