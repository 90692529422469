
.upload-link {
  color: #002E5E;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  text-decoration: underline;
  margin-top: 32px;
  display: block;
}
