
.upload-wrapper {
  .upload-link {
    color: #002E5E;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1;
    text-decoration: underline;
    margin-top: 32px;
    display: block;
  }
  @media (max-width: 768px) {
    display: flex;
    .upload-image {
      width: 170px;
    }
    .section__lk-content__right__avatar {
      width: 96px;
      height: 96px;
    }
    h2 {
      font-size: 18px;
      font-weight: 700;
    }
    .upload-link {
      font-size: 16px;
      margin-top: 12px;
    }
    margin: 16px 0 56px;
  }
  @media (max-width: 540px) {
    flex-direction: column;
    .upload-image {
      width: 96px;
    }
    .section__lk-content__right__avatar {
      width: 72px;
      height: 72px;
    }
    h2 {
      margin-top: 20px;
    }
  }
}

