/**
 * sitemap.scss
 */
.section__sitemap {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  &-list {

    > li  li  li {
      font-weight: 400;

      &:before {
        background-color: $blue-02;
      }
    }
  }
  ul {
    margin: 2.4rem 0 2.4rem 4.8rem;
    list-style: none;
    padding: 0;

    @media (max-width: 768px){
      margin: 24px 0 24px 45px;
    }

    &.section__sitemap-list {
      padding-left: 4.8rem;

      @media (max-width: 768px){
        padding-left: 26px;
        margin-left: 13px;
      }

      @media (max-width: 540px){
        margin-top: 40px;
      }
    }

    li {
      margin: 1rem 0;
      padding: 0;
      position: relative;
      font-size: 1.8rem;
      line-height: 1.3;
      font-weight: 700;

      @media (max-width: 768px){
        margin: 10px 0;
        font-size: 16px
      }

      &:before {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        top: 1.8rem * 1.3 / 2 - 1.2rem / 2;
        left: -4.8rem;
        background-color: $blue-03;
        content: '';
        position: absolute;

        @media (max-width: 768px){
          width: 12px;
          height: 12px;
          top: 16px * 1.3 / 2 - 12px / 2;
          left: -28px;
        }
      }

      a {
        color: $text;
        position: relative;
        text-decoration: none;

        &:before {
          position: absolute;
          bottom: -3px;
          left: 0;
          right: 0;
          height: 2px;
          background-color: $red;
          transition: .3s ease-in-out;
          opacity: 0;
          content: '';
        }

        &:hover {
          color: $text;

          &:before {
            opacity: 1;
            bottom: 0;
          }
        }
      }
    }
  }
}