/**
 * section
 */
.section {
  min-height: 100vh;
  padding: 20rem 0 5rem;
  align-items: center;
  display: flex;
  box-sizing: border-box;

  .fp-tableCell {
    display: flex;
    //min-height: 100%;
    max-width: 100%;
    height: auto !important;
  }

  @media (max-width: 768px){
    //align-items: flex-start;
    padding: 0 0 6rem;

    &:not(.section__search):not(.section__menu){
      min-height: auto;
      padding-top: 0;
    }
    &__lk {
      .section__subsection-wrapper {
        height: auto;
      }
    }
  }

  &__wrapper {

    padding: 0 19.5rem 0 calc(14.5rem + 115px);
    width: 100%;

    @media (max-width: 990px){
      padding: 0 60px 0 84px;
      margin: auto;
    }

    @media (max-width: 768px){
      padding: 0 16px;
    }
  }

  &__subsection_blue {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      background-color: $blue-03;
    }
  }

  &__subsection-wrapper {
    position: relative;
  }
}