/**
 * tabs
 */
.nav {
  &-tabs {
    border-bottom-width: .8rem;
    border-color: $primary;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      bottom: -0.8rem;
      height: 0.8rem;
      left: 0;
      right: 0;
      background: $primary;

      @media (max-width: 768px){
        bottom: -8px;
        height: 8px;
      }
    }

    @media (max-width: 768px){
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
        height: 0;
      }
    }

    @media (max-width: 540px){
      border-bottom-width: 8px;
    }
  }

  &-tabs &-link {
    border: 0;
    padding: 2.4rem;
    font-size: 1.8rem;
    box-shadow: $box-shadow;
    background: $white;
    color: $text;
    transition: .3s ease-in-out;

    @media (max-width: 768px){
      padding: 18px 24px 14px;
      font-size: 14px;
    }

    &:not(.active):hover {
      box-shadow: $box-shadow-hover;
    }

    &.active {
      background: $blue-02;
      color: $white;
      border: 0;
    }

    &.disabled {
      background-color: #E0E0E0;
      color: $grey-05;
    }
  }

  &-tabs &-item {
    margin-right: 2.4rem;
    margin-bottom: 0;
    border: 0;
  }

  // bordered
  &-tabs_bordered {
    border-bottom-width: 1px;
    border-color: $grey-04;

    @media (max-width: 540px) {
      border: 0;
    }

    &:after {
      display: none;

      @media (max-width: 540px) {
        display: block;
        bottom: 0;
        height: 1px;
        background: $grey-04;
        z-index: 0;
      }
    }
  }

  &-tabs_bordered &-link {
    border: 1px solid transparent;
    border-radius: 8px 8px 0 0;
    padding: 2.4rem 2.4rem 1.6rem;
    font-size: 1.8rem;
    font-weight: 600;
    box-shadow: none;
    background: transparent;
    color: $blue-04;
    transition: .3s ease-in-out;
    position: relative;
    top: 1px;

    &:not(.active):hover {
      box-shadow: none;
      border-color: transparent;
    }

    @media (max-width: 540px) {
      &:not(.active){
        border-bottom-color: $grey-04;
      }
    }

    @media (max-width: 768px){
      padding: 24px 24px 16px;
      font-size: 16px;
      line-height: 1;
    }

    @media (max-width: 540px) {
      padding: 16px;
      top: 0;
      z-index: 2;
    }

    &.active {
      background: transparent;
      color: $blue-03;
      border-width: 1px;
      border-color: $grey-04 $grey-04 $body-bg $grey-04;
      border-style: solid;

      span {
        background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='%23ED1B2F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='white'/%3E%3C/svg%3E");
      }
    }

    span {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-left: 1rem;
      transition: 0.3s ease-in-out;
      background: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='transparent'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='%23BDBDBD'/%3E%3C/svg%3E") no-repeat 50% 50% / contain;
      position: relative;

      @media (max-width: 768px){
        width: 16px;
        height: 15px;
      }
    }
  }

  &-tabs_bordered &-item {
    margin-right: 0;
    margin-bottom: 0;
    border: 0;
  }

  // underlined
  &-tabs_underlined {
    position: relative;

    @media (max-width: 768px){
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
        height: 0;
      }
    }

    &:after {
      display: none;
    }

    &-indicator {
      position: absolute;
      bottom: -3px;
      height: 3px;
      background: $red;
      padding: 0;
      margin: 0;
      transition: .3s ease-in-out;

      @media (max-width: 768px){
        bottom: 0;
      }
    }
  }

  &-tabs_underlined &-link {
    text-transform: uppercase;
    font-weight: 700;
    padding: 1.2rem 2.4rem;
    font-size: 1.8rem;
    line-height: 1.33;
    color: $text;

    @media (max-width: 768px){
      padding: 12px 24px;
      font-size: 16px;
    }

    &.active {
      background: $grey-06;
      color: $blue-03;
    }
  }


  // carousel
  &-tabs_carousel &-link {
    font-weight: 700;
    padding: 1.2rem 3.4rem;
    font-size: 1.8rem;
    line-height: 1;
    color: $text;
    height: 7.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: .8rem solid $blue-02;

    @media (max-width: 768px){
      border-bottom-width: 4px;
      font-size: 14px;
      line-height: 1.15;
      padding: 8px 12px;
      height: 44px;
    }
    background: $white;
    box-shadow: $box-shadow;
    text-align: center;
    transition: .3s ease-in-out;

    &.active {
      background: $blue-02;
      color: $white;
      border-color: $blue-03;
    }
  }

  &-tabs_carousel {
    padding-left: 7.2rem;
    padding-right: 7.2rem;

    @media (max-width: 540px) {
      padding-left: 56px;
      padding-right: 56px;
    }

    .owl-prev {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }

    .owl-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin: 0;
    }
  }
}