
.upload-container {
  padding: 5.8rem 13.1rem;
  background: url("/pic/upload-bg.svg") center center no-repeat;
  background-size: contain;
  margin: 2.4rem 0 3.2rem;
  &__icon,
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    text-align: center;
  }
  &__text {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #BDBDBD;
    margin-top: 2.4rem;
  }
}
.vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
  background: #002E5E;
  width: calc(100% + 8rem)!important;
}
.thumbnail {
  min-height: 21rem;
}
input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
  direction: ltr;
}

.cropper {
  max-height: 450px;
  max-width: 100%;
}

