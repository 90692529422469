/**
 * program-card
 */
.program-card {
  background: $white;
  padding: 2.4rem;
  margin-bottom: 2.4rem;

  @media (max-width: 768px) {
    padding: 24px 24px 80px;
    margin-bottom: 24px;
    position: relative;
  }

  @media (max-width: 540px) {
    padding: 16px 16px 128px;
  }

  &__grid {
    display: flex;
    align-items: stretch;

    @media (max-width: 768px) {
      flex-flow: wrap;
    }

    &-img {
      flex: 0 0 25.5%;
      max-width: 25.5%;
      height: 35rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
        height: 240px;
      }

      img {
        width: 100%;
        height: 100%;
        flex-grow: 0;
        object-fit: cover;
      }
    }

    &-data {
      flex-grow: 1;
      padding: 0 2.4rem;
      display: flex;
      flex-direction: column;

      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 24px 0;
        padding: 0;
      }

      @media (max-width: 540px) {
        margin-top: 12px;
      }
    }

    &-content {
      flex: 1 1 auto;
      font-size: 1.8rem;
      margin-bottom: 2.4rem;
      color: $grey-05;

      @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    &-date {
      font-size: 1.8rem;
      margin-bottom: 3.2rem;
      color: $blue-02;

      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }

    &-title {
      font-size: 3.2rem;
      line-height: 1;
      margin-bottom: 2.4rem;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 24px;
        line-height: 1;
      }

      a {
        color: $text;
      }
    }

    &-links {
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        display: inline-flex !important;
        flex: 0 0 auto;
        max-width: 0;
        position: absolute;
        left: 24px;
        bottom: 24px;

        .link-target {
          margin-right: 24px;
          white-space: nowrap;
        }
      }

      .mt-1:last-child{
        order: -1;
      }

      @media (max-width: 540px) {
        flex-flow: wrap;

        .mt-1 {
          margin-top: 16px !important;
        }
      }
    }

    &-info {
      flex: 0 0 calc(33.3% - 6rem);
      max-width: calc(33.3% - 6rem);

      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &-item {
        display: flex;
        margin-bottom: 2.4rem;
        font-size: 1.8rem;

        @media (max-width: 768px) {
          font-size: 16px;
          margin-bottom: 16px;
        }
      }

      &-label {
        margin-right: 1.8rem;
        text-align: right;
        flex: 0 0 12rem;
        max-width: 12rem;

        @media (max-width: 768px) {
          flex: 0 0 99px;
          max-width: 99px;
          text-align: left;
        }

        color: $blue-02;
      }

      &-content {
        flex-grow: 1;
        color: $blue-03;

        @media (max-width: 540px) {
          font-size: 16px;
        }

        a {
          font-weight: 600;
          color: $blue-03;
        }
      }

      &_rate {
        color: $red;

        a {
          color: $red;
          text-decoration: underline;
        }
      }
    }
  }
}