/**
 * notice
 */
.notice {
  border-left: 0.8rem solid;
  padding: 2rem 2.4rem;

  @media (max-width: 768px) {
    padding: 20px 24px;
  }

  @media (max-width: 540px) {
    padding: 0 0 0 16px;

    &.container-shadow {
      padding: 2px 16px;
    }
  }

  &_red {
    border-color: $red;
  }

  &_blue {
    border-color: $blue-03;
  }

  h2,
  h2.notice__title {
    margin-bottom: 1.6rem;
    font-size: 2.4rem;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 24px;
    }

    @media (max-width: 540px) {
      font-size: 16px;
    }
  }

  &__content {
    font-size: 1.8rem;
    line-height: 1.75;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  p:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  .list {

    &_dotted li {
      line-height: 1.5;
      margin-bottom: 0;

      &:before {
        background-color: $text;
        top: 1.8rem * 1.5 / 2 - .15rem;
      }
    }
  }
}