$screen-sm: 992px;
$grey-extra-light: #f5f5f5;
$icons-main: url('/images/icons-main.png') no-repeat;
$grey-light-darker: #e5e5e5;
$grey-light-darkest: #dcdcdc;
$grey-middle-lead: #c7cbd0;
$grey-dim: rgba(52, 54, 75, .45);
$line-height-m: 1.3em;

$blue:    #007bff !default;
$blue-darker: #0861d5;
$text-color: #34364b;
$violet-dim: rgba(26, 32, 77, .3);

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -moz-box-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -moz-box-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -moz-box-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
    -moz-box-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin link-near-black {
  color: $text-color;
  border-bottom-color: $violet-dim;

  &:hover {
    color: $blue;
    border-bottom-color: transparent;
  }
  &:focus {
    color: $blue-darker;
  }
}

@mixin flex-wrap($wrap: nowrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

// flexbox
@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.recommend-block {
  &__item {
    p, a {
      margin: 32px 0 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 19px;
    }
  }
  padding-bottom: 32px;
  .block-course-component {
    display: block;
    padding: 25px;
    cursor: pointer;
    border: none;
    color: #34364BFF;

    &:hover,
    &:focus {
      color: #34364BFF;
    }
    @media (min-width: $screen-sm) {
      padding: 25px 40px;
    }
    &__wrap {
      &:nth-child(odd) {
        background-color: $grey-extra-light;
        background-position:bottom;
      }
    }
    &__inner {
      position: relative;
      @include clearfix;
    }
    &__left {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 100%;
      @include flexbox;
      @include align-items(center);
    }
    &__icon {
      display: block;
      width: 34px;
      height: 34px;
      background: $icons-main;
    }
    &__middle {
      margin-left: 60px;
      padding-right: 15px;

      @media (min-width: $screen-sm) {
        margin-right: 200px;
        padding-left: 15px;
        border-left: 2px solid $grey-light-darker;
        border-right: 1px solid $grey-middle-lead;
      }
    }
    &__type {
      font-weight: 600;
    }
    &__title {
      margin-right: 20px;
    }
    &__event-title {
      color: $grey-dim;
    }
    &__right {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 200px;
      height: 100%;
      padding-left: 15px;

      &__inner {
        position: relative;
        height: 100%;
        padding-right: 30px;
      }
    }
    &__stat {
      display: none;
      height: 100%;
      font-size: 13px;
      line-height: $line-height-m;

      @media (min-width: $screen-sm) {
        @include flexbox;
        @include flex-wrap(wrap);
        @include align-items(center);
        @include align-content(center);
      }

      .block-inline-sm {
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__status {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      @include flexbox;
      @include align-items(center);

      .icon {
        display: block;
        width: 24px;
        height: 24px;
        background: $icons-main 0 -1000px;
      }
    }
    .block-float {
      .block-course-component__stat__text {
        float: left;
      }
      a {
        @include link-near-black;
      }
    }

    // statuses
    &.completed {
      .block-course-component__status {
        .icon {
          background-position: 0 -1000px!important;
        }
      }
    }
    &.active {
      .block-course-component__status {
        .icon {
          background-position: -50px -1000px!important;
        }
      }
    }
    &.locked {
      .block-course-component__status {
        .icon {
          background-position: -100px -1000px!important;
        }
      }
    }
    &.danger {
      .block-course-component__status {
        .icon {
          background-position: -150px -1000px!important;
        }
      }
    }
    &.draft {
      .block-course-component__status {
        .icon {
          background-position: -200px -1000px!important;
        }
      }
    }
    &.pending {
      .block-course-component__status {
        .icon {
          background-position: -250px -1000px!important;
        }
      }
    }
    &.success {
      .block-course-component__status {
        .icon {
          background-position: -342px -998px!important;
        }
      }
    }

    // types
    &.request {
      .block-course-component {
        &__icon {
          background-position: -300px -797px;
        }
        &__type {
          color: $grey-middle;
        }
      }
    }
    &.event {
      .block-course-component {
        &__icon {
          width: 38px;
          background-position: -102px -897px;
        }
        &__right__inner {
          padding-right: 0;
        }
        &__stat {
          @include flex-wrap(nowrap);

          &__text {
            margin-left: 5px;
          }
        }
      }
    }
    &.certificate {
      .block-course-component {
        &__icon {
          background-position: -395px -901px;
        }
        &__status {
          .icon {
            background-position: -250px -1000px;
          }
        }
      }
    }
    &.document {
      .block-course-component {
        &__icon {
          background-position: -195px -897px;
        }
        &__status {
          .icon {
            background-position: -250px -1000px;
          }
        }
      }
    }
    &.video {
      .block-course-component {
        &__icon {
          background-position: 2px -796px;
        }
      }
    }
    &.test {
      .block-course-component {
        &__icon {
          background-position: -98px -798px;
        }
      }
    }
    &.tutorial {
      .block-course-component {
        &__icon {
          width: 42px;
          background-position: -102px -898px;
        }
      }
    }
    &.clock {
      .block-course-component {
        &__icon {
          background-position: -200px -798px;
        }
      }
    }
    &.mail {
      .block-course-component {
        &__icon {
          width: 41px;
          height: 25px;
          background-position: -400px -800px;
        }
        &__stat {
          @include justify-content(space-between);
        }
        &__right__inner {
          padding-right: 0;
        }
      }
    }
    &.stat {
      .block-course-component {
        &__icon {
          background-position: 0 -897px;
        }
        &__stat {
          @include justify-content(space-between);
        }
        &__right__inner {
          padding-right: 0;
        }
      }
    }
  }
}