.ffg {
  h1 {
    margin-right: 4.8rem;
    margin-top: 10.8rem;
    font-size: 56px;
    line-height: 72px;
    text-align: left;
    letter-spacing: 0;
    &.main-title {
      color: $pink;
      font-family: Circe;
      font-weight: 700;
    }
    @media (max-width: 767px) {
      &.main-title {
        margin-right: 20px;
        margin-top: 20px;
      }
      font-size: 24px;
      line-height: 32px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 3.6rem;
  }
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    &-mission {
      h1 {
        margin-top: 15.6rem!important;
      }
    }
    img {
      margin-top: 10.8rem;
      width: 5.2rem;
      position: relative;
      top: -1.4rem;
    }
    @media (max-width: 767px) {
      align-items: end;
      img {
        top: -5px;
        margin-top: 0;
      }
    }
  }
  &__text {
    font-size: 1.8rem;
    line-height: 3.2rem;
    margin-top: 2.4rem;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
      width: 85%;
    }
  }
  &__girls-chart {
    text-align: center;
    @media (max-width: 767px) {
      margin-bottom: 32px;
      img {
        max-height: 295px;
      }
    }
  }
  &__carousel {
    padding: 3.3rem 4.25rem 4rem 4.3rem;
    .VueCarousel {
      position: relative;
      &-navigation {
        position: absolute;
        right: 61px;
        top: -40px;
        @media (max-width: 767px) {
          right: 50%;
          top: -40px;
        }
        @media (min-width: 767px) {
          top: -30px;
        }
        button {
          &:focus {
            outline: none!important;
          }
        }
      }
      &-slide {
        min-height: 29.7rem;
        .ffg__carousel__item {
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 400;
          line-height: 3.2rem;
          height: 100%;
          padding: 2.5rem;
          margin: 0 0.75rem;
          background-color: $bg-blue;
          &__text {
            margin-top: 10px;
          }
          @media (max-width: 767px) {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px
          }
        }
        &:nth-child(even) {
          .ffg__carousel__item {
            background-color: $bg-pink;
          }
        }
      }
    }
  }
  .percent {
    &-title {
      line-height: 1;
      font-size: 6.4rem;
      font-weight: 200;
      margin: 0.5rem;
    }
    &-people {
      line-height: 1;
      font-size: 3.2rem;
      font-weight: 350;
      margin: 0.5rem;
      @media (max-width: 767px) {
        font-size: 30px;
        font-style: normal;
        font-weight: 350;
        line-height: 32px;
      }
    }
    &-text {
      line-height: 1;
      font-weight: 400;
      font-size: 2.4rem;
      margin: 0.5rem;
      @media (max-width: 767px) {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 68px;
      }
    }
  }
  .ffg__timeline {
    h1 {
      margin-top: 11.8rem!important;
    }
    h2 {
      margin-top: 4.8rem;
    }
    p {
      line-height: 2.4rem;
      font-size: 2.4rem;
      margin: 1.6rem 0 3.2rem;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
    hr {
      margin: 1.6rem 0 1.6rem;
      border: 0.2rem solid #D76285;
    }
    &__line {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 4.8rem;
      line-height: 1;
      > * {
        background: #D76285;
      }
      &-start {
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
      }
      &-middle {
        height: 0.3rem;
        flex: 1;
      }
      &-end {
        background: none;
        border: solid #D76285;
        border-width: 0 0.3rem 0.3rem 0;
        display: inline-block;
        padding: 0.6rem;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        position: relative;
        left: -1.6rem;
      }
      @media (max-width: 767px) {
        display: none;
      }
    }
    &__header {
      margin-top: 5.6rem;
      background: #D76285;
      padding: 2.4rem 4rem;
      color: #fff;
      display: inline-block;
      font-size: 2.4rem;
      line-height: 1;
      position: relative;
      @media (max-width: 767px) {
        &.first {
          margin-top: 10px;
        }
        margin-top: 56px;
      }
      &:before {
        content: "";
        height: 6.4rem;
        width: 0.3rem;
        background: #D76285;
        position: absolute;
        top: -6.4rem;
        left: calc(50% - 0.15rem);
        @media (max-width: 767px) {
          content: "";
          height: 2px;
          width: 48px;
          background: #D76285;
          position: absolute;
          top: calc(50% - 1px);
          left: -48px;
        }
      }
    }
    &__text {
      font-size: 1.8rem;
      line-height: 2.4rem;
      a {
        font-weight: 700;
        text-decoration: underline;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .link-target {
      margin-top: 3.2rem;
    }
    .container-shadow {
      margin: 12rem 0 7.8rem;
      img {
        width: 100%;
      }
      h2 {
        margin-top: 7.6rem;
      }
      .d-flex {
        padding-right: 2.4rem;
      }
      .tooltip-block {
        position: relative;
        .tooltip-button {
          position: absolute;
          right: 4rem;
          top: 2.4rem;
        }
        @media (max-width: 767px) {
          padding: 24px 24px 24px 39px;
          h2 {
            margin-top: 0;
            font-size: 18px;
            line-height: 24px;
          }
          .tooltip-button {
            left: 39px;
            top: calc(100% - 52px);
            height: 28px;
            width: 28px;
          }
        }
      }
    }
    > .row {
      @media (max-width: 767px) {
        margin-top: 32px;
        margin-left: -12px;
        .col-md-4 {
          &:first-child {
            &:before {
              width: 10px;
              height: 10px;
              content: "";
              border-radius: 50%;
              background: #D76285;
              position: absolute;
              left: -6.5px;
            }
          }
          &:last-child {
            &:after {
              background: none;
              border: solid #D76285;
              border-width: 0 3px 3px 0;
              display: inline-block;
              padding: 6px;
              transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              position: absolute;
              bottom: 0;
              left: -9px;
              content: "";
            }
          }
          border-left: 3px solid #D76285;
          margin-left: 16px;
          padding-left: 48px;
        }
      }
    }
  }
  &__mission {
    &__icon {
      margin-top: 2.84rem;
      img {
        max-width: 90%;
        &.show-lg {
          display: block;
        }
        &.show-md {
          display: none;
        }
        @media (max-width: 767px) {
          &.show-lg {
            display: none;
          }
          &.show-md {
            display: block;
          }
        }
      }
    }
    &__list {
      h2 {
        margin: 5rem 0 4rem;
        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
      ul {
        list-style: none;
        font-size: 2.4rem;
        line-height: 3.2rem;
        padding: 0;
        li {
          padding: 0.7rem 0 2.4rem 4.8rem;
          position: relative;
          &::before {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background-image: url("/pic/ffg/icon-bullet.svg");
            background-repeat: no-repeat;
            background-size: cover;
            content: "";
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1.2rem;
            @media (max-width: 767px) {
              width: 16px;
              height: 16px;
              top: 4px;
              font-size: 16px;
              line-height: 16px;
            }
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }
  &__girls-chart {
    padding-right: 2.4rem;
    img {
      max-width: 100%;
    }
  }
  &__chat {
    padding: 4.8rem;
    font-size: 1.8rem;
    line-height: 3.2rem;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 16px;
      padding: 16px;
    }
    .d-flex {
      &:last-child {
        margin-top: 4rem;
      }
    }
    &__chat {
      padding: 2.4rem 3.2rem;
      position: relative;
      &-blue {
        background: #D7E8F8;
        flex: 1;
        &__arrow {
          position: absolute;
          right: -4.2rem;
          top: 0;
          width: 4.5rem;
          height: 3.2rem;
          background-image: url('/pic/ffg/icon-red-arrow-right.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      &-red {
        background: #FACEDB;
        &__arrow {
          position: absolute;
          left: -4.2rem;
          top: 0;
          width: 4.5rem;
          height: 3.2rem;
          background-image: url('/pic/ffg/icon-red-arrow-left.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
    &__avatar {
      width: 8rem;
      img {
        max-width: 100%;
        @media (max-width: 767px) {
          max-width: 42px;
        }
      }
      &-blue {
        margin-left: 9rem;
        @media (max-width: 767px) {
          margin-left: 29px;
        }
      }
      &-red {
        margin-right: 9rem;
        @media (max-width: 767px) {
          margin-right: 29px;
        }
      }
    }
  }

  &__content {
    margin-top: 14.6rem;
    h1 {
      margin: 0 0 3.2rem!important;
    }
    h2 {
      margin-bottom: 2.5rem;
    }
    &__text {
      font-size: 3.2rem;
      line-height: 3.6rem;
      font-weight: 400;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: flex-end;
      }
      img {
        max-width: 100%;
        position: relative;
        bottom: -2px;
        @media (max-width: 767px) {
          max-width: 70%;
        }
      }
    }
    &-container {
      overflow: hidden;
      position: relative;
      padding: 3.2rem 2.4rem 2.4rem 2.4rem;
      min-height: 2.17rem;
      p {
        font-size: 2.4rem;
        line-height: 1;
        padding-bottom: 3.2rem;
        margin: 0;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
      li {
        z-index: 2;
        font-size: 1.8rem;
        line-height: 3.2rem;
        padding: 0 0 1.2rem 2.4rem;
        &:last-child {
          padding-bottom: 0;
        }
        position: relative;
        &::before {
          width: 1rem;
          height: 1rem;
          position: absolute;
          left: 1rem;
          top: 1rem;
        }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
          padding: 0 0 0 24px;
          &::before {
            width: 8px;
            height: 8px;
            position: absolute;
            left: 8px;
            top: 8px;
          }
        }
      }
    }
    &-number {
      position: absolute;
      right: 6.4rem;
      bottom: -4.7rem;
      line-height: 24rem;
      font-size: 26rem;
      color: #FACEDB;
      font-weight: 700;
      z-index: 1;
      &-one {
        right: 8.8rem;
      }
    }
  }

  &__key {
    .ffg__title {
      margin-bottom: 5.8rem;
      h1 {
        margin-top: 15.6rem;
      }
    }
    .container-shadow {
      padding: 6.7rem 4rem 4rem;
      position: relative;
      &.open {
        padding: 3.5rem 4rem;
      }
      @media (max-width: 767px) {
        padding: 0 28px 28px;
      }
    }
    &__link {
      margin-top: 6.8rem;
      @media (max-width: 767px) {
        &-rec {
          margin-top: 72px;
        }
        &-shrec {
          margin-top: 49px;
        }
        &-br {
          margin-top: 79px;
        }
      }
    }
    &__icon {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      &-rec {
        img {
          max-width: 36rem;
          @media (max-width: 767px) {
            max-width: 259px;
            margin-top: 70px;
          }
        }
      }
      &-shrec {
        img {
          max-width: 22.9rem;
          @media (max-width: 767px) {
            max-width: 162px;
            margin-top: 53px;
          }
        }
      }
      &-br {
        img {
          max-width: 34.7rem;
          @media (max-width: 767px) {
            max-width: 248px;
            margin-top: 103px;
          }
        }
      }
    }
    &__more-info {
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 32px;
      }
      &__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .logo {
          margin-bottom: 3rem;
          &-rec {
            width: 15.2rem;
            @media (max-width: 767px) {
              width: 152px;
            }
          }
          &-shrec {
            width: 7.7rem;
            @media (max-width: 767px) {
              width: 77px;
            }
          }
          &-br {
            width: 18.4rem;
            @media (max-width: 767px) {
              width: 184px;
            }
          }
        }
      }
    }
  }
  &__modal {
    form {
      padding-top: 4rem;
    }
    .modal-dialog {
      max-width: 680px!important;
      font-size: 1.8rem;
      line-height: 2.4rem;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }
      .modal-header {
        padding-bottom: 1.6rem;
        .title-with-icon__title {
          font-size: 3.2rem;
          line-height: 3.6rem;
          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
      .modal-body {
        label:not(.form-check-label) {
          color: #828282;
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
    .form-result {
      background: url("/pic/ffg/union-up.svg") #fff center bottom no-repeat;
      .modal-body {
        padding: 0 4rem 0;
      }
      .btn-rounded {
        margin-bottom: 3.2rem;
        border-radius: 2.4rem;
      }
    }
  }
}