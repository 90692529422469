.editr {
  border: none;
  &--toolbar {
    background-color: #fff!important;
    margin-bottom: 24px;
    box-shadow: $box-shadow-default;
    transition: box-shadow ease-in-out .5s;
    border-bottom: 0;
    height: 46px;
    padding: 0 12px;
    align-items: center;
    .vw-separator {
      display: none;
    }
  }
  &--content {
    background-color: #fff;
  }
}