/**
 * doc.scss
 */
.doc {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;


  &__content {
    flex: 1 1 auto;
    flex-flow: nowrap;

    @media (max-width: 540px) {
      flex-flow: wrap;
    }
  }

  @media (max-width: 768px) {
    .ext-wrapper {
      margin-left: 15px !important;
    }
  }

  @media (max-width: 540px) {
    .ext-wrapper {
      margin-left: 0 !important;
      margin-bottom: 16px !important;
    }
  }

  .col {
    @media (max-width: 540px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__title {
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 2.4rem;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 24px;
    }

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 1.33;
      margin-bottom: 24px;
    }

    + .content_18 {
      margin: -1.2rem 0 2.4rem;
    }


  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}