/**
 * radio
 */
.radio {
  display: inline-block;
  position: relative;


  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:not(:checked){
      &:hover {
        + label:before {
          background: $blue-02;
        }
      }

      &:focus {
        + label:before {
          border-color: $blue-02;
        }
      }
    }

    + label {
      padding-left: 3.6rem;
      font-size: 1.8rem;
      line-height: 1.35;
      position: relative;

      &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 2.4rem;
        height: 2.4rem;
        content: '';
        border: 2px solid $blue-02;
        transition: border-color .3s ease-in-out;
        border-radius: 50%;
        background: $white;
      }

      @media (max-width: 768px){
        font-size: 16px;
        padding-left: 36px;

        &:before {
          width: 24px;
          height: 24px;
        }
      }

      a {
        text-decoration: underline;
        color: $red;
      }
    }

    &:checked {
      + label:before {
        border-width: .6rem;

        @media (max-width: 768px){
          border-width: 6px;
        }
      }

      &:hover {
        + label:before {
          border-color: $blue-03;
        }
      }

      &:focus {
        + label:before {
          border-color: $blue-03;
        }
      }
    }

  }

}