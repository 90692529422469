.section__teams {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  h2 {
    font-weight: 700;
    font-size: 3.2rem;

    @media (max-width: 768px){
      font-size: 24px;
    }
  }

  p {
    @media (max-width: 768px){
      margin-bottom: 15px;
    }
  }

  @media (max-width: 768px){
    .section__coaches-carousel .owl-nav, .section__coaches-partner-carousel .owl-nav {
      top: -42px;
      right: auto;
      left: calc(100vw - 16px - 112px);
    }
  }

  .content_18 {
    a {
      color: $red;
      text-decoration: underline;
    }

    @media (max-width: 768px){
      font-size: 16px;
    }
  }

  .notice.container-shadow {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @media (max-width: 540px){
    .notice.mb-4 {
      //margin-bottom: 32px !important;
    }
  }

  &-form {
    max-width: 67rem;
    margin: 0 auto 7rem;

    @media (max-width: 768px){
      max-width: 100%;
      margin-left: 24px;
      margin-right: 24px;
    }

    @media (max-width: 540px){
      margin-left: 0;
      margin-right: 0;
    }
  }

  &-points {

    img {
      max-width: 100%;
      display: block;
    }

    @media (max-width: 576px){
      &-wrapper {
        margin-right: 6px;
        margin-bottom: 32px;
        padding: 8px 16px 8px 8px;
        border-color: $blue-02;
        border-style: solid;
        border-width: 1px 0 1px 1px;
        overflow-y: hidden;
        overflow-x: auto;

        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */

        &::-webkit-scrollbar {
          background: transparent; /* Chrome/Safari/Webkit */
          width: 0;
          height: 0;
        }

        > .row {
          width: 240vw;
        }
      }
    }

    .content_title {
      line-height: 1;
    }

    @media (max-width: 768px){
      .content_subtitle {
        font-size: 18px;
        word-break: break-word;
        hyphens: auto;
      }
    }
    @media (max-width: 650px){
      .content_subtitle {
        font-size: 16px;
      }
    }
  }

  .section__teams-scheme-down {
    width: 5.6rem;
    height: 5.6rem;
  }

  .section__teams-scheme-plus {
    width: 4.8rem;
    height: 4.8rem;
  }

  @media (max-width: 768px){
    .container-grid .container-grid__item {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .item-description {
      font-size: 16px;
    }

    .notice h2.notice__title {
      font-size: 18px;
      margin-bottom: 24px;
    }

    .notice_content,
    .notice__content {
      font-size: 16px;

      .list li:before {
        top: 10px;
      }
    }

    .content_18 .list_dotted li:before {
      top: 10px;
    }
  }
}