/**
 * avatar.scss
 */
.avatar {

  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }

  &_sm {
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 2.2rem;

    @media (max-width: 768px){
      width: 48px;
      height: 48px;
    }
  }

  &_big {
    width: 12rem;
    height: 12rem;
    border-radius: 7.2rem;

    @media (max-width: 768px){
      width: 100px;
      height: 100px;
    }
  }

  &_middle {
    width: 9.6rem;
    height: 9.6rem;
    border-radius: 7.2rem;

    @media (max-width: 768px){
      width: 78px;
      height: 78px;
    }
  }
}