
.notification-bell {
  position: relative;
  .notificationBell {
    position: absolute;
    top: -30px;
    cursor: pointer;
  }
}
.notification-counter {
  width: 15px!important;
  height: 15px!important;
  margin-top: -5px!important;
  font-size: 10px;
}
.bell-wrapper {
  position: absolute;
  width: 368px;
  right: 0;
  top: 0;
  background-color: #fff;
  padding: 20px 14px 14px;
  z-index: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.bell-wrapper__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  a {
    text-align: right;
  }
}
.bell-list {
  margin: 0;
  padding: 0;
  max-height: 300px;
  overflow: auto;
}
.bell-list li {
  background-color: #f5f5f5;
  margin-bottom: 10px;
}
.bell-list-clear {
  display: block;
  line-height: 0;
  border: 0;
  margin-right: 10px;
  cursor: pointer;
}
.bell-wrapper__title {
  line-height: 15px;
  font-weight: 600;
  color: #2f2d2d;
}
