/**
 * course
 */
.section__course {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  &-content {

    @media (min-width: 1250px) {
      margin-right: 9rem;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 3.2rem;

    @media (max-width: 768px){
      font-size: 24px;
    }

    @media (max-width: 540px){
      line-height: 1;
      margin-bottom: 12px;
    }
  }

  h1 {
    font-weight: 400;
    font-size: 4.8rem;
    line-height: 1.5;
    margin-bottom: 2.4rem;
    text-transform: uppercase;

    @media (max-width: 768px){
      font-size: 36px;
      line-height: 1.3;
    }

    @media (max-width: 540px){
      font-size: 24px;
      line-height: 1.4;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 1.75;

    @media (max-width: 768px){
      font-size: 16px;
    }
  }

  .preview-video {
    height: 43rem;
  }

  &-text {
    font-size: 1.8rem;
    line-height: 1.75;

    .list li:before {
      background-color: $text;
      top: 1.5rem;
    }

    @media (max-width: 768px){
      font-size: 16px;
      line-height: 1.5;

      .list li {
        font-size: 16px;
        line-height: 1.5;
        padding-left: 30px;

        &:before {
          top: 11px;
        }
      }
    }
  }

  &-discussion {
    &-zoom {
      margin: 12rem 0 9rem;
      width: 20rem;
      height: 20rem;
      background-size: 20rem auto;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='201' height='201' viewBox='0 0 201 201' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M198.099 183.9L160.999 147.1C175.4 129.144 182.374 106.353 180.487 83.4133C178.6 60.4733 167.996 39.1281 150.854 23.7667C133.713 8.40533 111.337 0.195365 88.3283 0.824969C65.3194 1.45457 43.4263 10.8759 27.1505 27.1517C10.8747 43.4275 1.45335 65.3207 0.823749 88.3295C0.194145 111.338 8.40411 133.714 23.7655 150.855C39.1269 167.997 60.4721 178.601 83.412 180.488C106.352 182.375 129.143 175.401 147.099 161L183.899 197.8C184.829 198.738 185.935 199.482 187.153 199.989C188.372 200.497 189.679 200.758 190.999 200.758C192.319 200.758 193.626 200.497 194.845 199.989C196.063 199.482 197.169 198.738 198.099 197.8C199.901 195.936 200.909 193.444 200.909 190.85C200.909 188.257 199.901 185.765 198.099 183.9ZM90.9991 161C77.1544 161 63.6206 156.895 52.1091 149.203C40.5977 141.511 31.6256 130.579 26.3275 117.788C21.0293 104.997 19.6431 90.9226 22.3441 77.344C25.045 63.7653 31.7119 51.2925 41.5016 41.5028C51.2913 31.7131 63.7641 25.0463 77.3427 22.3453C90.9214 19.6443 104.996 21.0306 117.787 26.3287C130.578 31.6268 141.51 40.5989 149.202 52.1104C156.894 63.6218 160.999 77.1556 160.999 91.0003C160.999 109.565 153.624 127.37 140.497 140.498C127.369 153.625 109.564 161 90.9991 161Z' fill='%2371CFEB'/%3E%3C/svg%3E ");

      @media (max-width: 768px) {
        width: 180px;
        height: 180px;
        background-size: contain;
      }
    }
    &-title {
      margin-bottom: 2.4rem;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 1;

      @media (max-width: 768px) {
        margin-bottom: 24px;
        font-size: 20px;
      }
    }
    &-text {
      font-size: 1.8rem;
      line-height: 1.75;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}