/**
 * table.scss
 */
.table {
  &-wrapper {

    &_scrolled {
      margin-right: -15px;
      padding: 1px 15px 1px 1px;
      overflow-y: hidden;
      overflow-x: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
        height: 0;
      }
    }

    &_indicator {
      width: 74px;
      height: 32px;
      background: url("/pic/icon-scroll.png") no-repeat 0 0 / contain;
    }

    table {
      width: 100%;
    }

    td {
      padding: 2.4rem;
      font-size: 1.8rem;
      line-height: 1.3;
      color: $text;

      @media (max-width: 768px) {
        padding: 24px;
        font-size: 16px;
      }

      @media (max-width: 540px) {
        padding: 16px;
      }
    }

    th {
      padding: 1.2rem 2.4rem;
      line-height: 1.3;
      font-size: 1.8rem;
      font-weight: 600;
      color: $blue-03;

      @media (max-width: 768px) {
        padding: 12px 24px;
        font-size: 16px;
      }
    }

    thead tr {
      background-color: #E0E0E0;
    }

    tbody {
      tr:nth-child(2n) {
        background-color: $white;
      }
    }
  }

  &_bordered {
    box-shadow: 0 0 0 1px $blue-02;

    tr {
      background-color: #fafafa;
    }
    tr th {
      border-bottom: 1px solid #f2f2f2;

      &:not(:last-child) {
        border-right: 1px solid #f2f2f2;
      }
    }
    tr {
      td:not(:last-child) {
        border-right: 1px solid #f2f2f2;
      }

      &:not(:last-child) td {
        border-bottom: 1px solid #f2f2f2;
      }

      td {
        a {
          color: $red;
          text-decoration: underline;
        }
      }
    }
  }

  &_cell_unborded {
    tr th {
      border-bottom: 0;

      &:not(:last-child) {
        border-right: 0;
      }
    }
    tr {
      td:not(:last-child) {
        border-right: 0;
      }

      &:not(:last-child) td {
        border-bottom: 0;
      }
    }
  }
}