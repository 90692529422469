.tooltip-button {
  cursor: pointer;
  display: inline-block;
  background-image: url('/pic/icon-tooltip-question.svg');
  width: 3.6rem;
  height: 3.6rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  position: relative;
}
.tippy-box {
  background-color: #fff;
  color: #1C75BC;
  box-shadow: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.08), 0 0.2rem 0.6rem rgba(0, 0, 0, 0.04), 0 0 0.1rem rgba(0, 0, 0, 0.04);
  border-color: #fff;
  font-weight: 700;
  border-radius: 1.6rem;
  padding: 2.6rem 3.2rem;
  font-size: 16px;
  line-height: 24px;
  .tippy-arrow {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-left-width: 1.1rem;
    border-right-width: 1.1rem;
    border-top: 1.3rem solid #fff;
    bottom: -1.2rem;
  }
}