/**
 * accordion
 */
.accordion {
  margin-bottom: 4.8rem;

  @media (max-width: 768px){
    margin-bottom: 48px;
  }

  &__item {
    margin-bottom: 2.4rem;
    background: $white;

    @media (max-width: 768px){
      margin-bottom: 24px;
    }
  }

  &__header {
    padding: 2.4rem;
    cursor: pointer;

    @media (max-width: 768px){
      padding: 24px;
    }

    h2 {
      font-size: 2.4rem;
      line-height: 1;
      font-weight: 700;
      position: relative;
      padding-right: 4rem;

      &:after {
        position: absolute;
        content: '';
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        background: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71006L2.45995 1.17006C2.27259 0.983805 2.01913 0.879264 1.75495 0.879264C1.49076 0.879264 1.23731 0.983805 1.04995 1.17006C0.95622 1.26302 0.881825 1.37362 0.831057 1.49548C0.780288 1.61734 0.75415 1.74804 0.75415 1.88006C0.75415 2.01207 0.780288 2.14277 0.831057 2.26463C0.881826 2.38649 0.95622 2.49709 1.04995 2.59006L5.28995 6.83006C5.38291 6.92378 5.49351 6.99818 5.61537 7.04895C5.73723 7.09972 5.86794 7.12585 5.99995 7.12585C6.13196 7.12585 6.26267 7.09972 6.38453 7.04895C6.50638 6.99818 6.61699 6.92378 6.70995 6.83006L10.9999 2.59006C11.0926 2.49662 11.166 2.3858 11.2157 2.26396C11.2655 2.14212 11.2907 2.01166 11.2899 1.88006C11.2907 1.74845 11.2655 1.61799 11.2157 1.49615C11.166 1.37431 11.0926 1.2635 10.9999 1.17006C10.8126 0.983805 10.5591 0.879263 10.2949 0.879263C10.0308 0.879263 9.77731 0.983805 9.58995 1.17006L5.99995 4.71006Z' fill='%231C75BC'/%3E%3C/svg%3E") no-repeat 50% 50%;
        background-size: 1.2rem auto;
        right: 0;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        transition: .3s ease-in-out;
      }

      @media (max-width: 768px){
        font-size: 18px;
        line-height: 1.33;

        &:after {
          width: 28px;
          height: 28px;
          background-size: 12px auto;
        }
      }

      @media (max-width: 540px){
        padding-right: 35px;
      }

      &.collapsed {
        &:after {
          transform: translateY(-50%);
        }
      }
    }
  }

  &__body {
    margin: 0 2.4rem;
    padding: 2.4rem 0;
    cursor: pointer;
    border-top: 1px solid $grey-04;

    @media (max-width: 768px){
      margin: 0 24px;
      padding: 24px 0;
    }
  }
}