/**
 * checkbox
 */
.checkbox {
  display: inline-block;
  position: relative;


  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:not(:checked){
      &:hover {
        + label:before {
          background: $blue-02;
        }
      }

      &:focus {
        + label:before {
          border-color: $blue-02;
        }
      }
    }

    + label {
      padding-left: 3.6rem;
      font-size: 1.8rem;
      line-height: 1.35;
      position: relative;

      &:before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 2.4rem;
        height: 2.4rem;
        content: '';
        border: 2px solid $blue-02;
        transition: border-color .3s ease-in-out, background-color .3s ease-in-out;
        border-radius: 2px;
        background: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 10L0 5.19231L1.4 3.84615L5 7.30769L12.6 0L14 1.34615L5 10Z' fill='%23ffffff'/%3E%3C/svg%3E") no-repeat 50% 50% / 0 0;
      }

      @media (max-width: 768px){
        font-size: 16px;
        padding-left: 36px;

        &:before {
          width: 24px;
          height: 24px;
        }
      }

      a {
        text-decoration: underline;
        color: $red;
      }
    }

    &:checked {
      + label:before {
        background-size: 1.4rem auto;
        background-color: $blue-02;

        @media (max-width: 768px){
          background-size: 14px auto;
        }
      }

      &:hover {
        + label:before {
          background-color: $blue-03;
          border-color: $blue-03;
        }
      }

      &:focus {
        + label:before {
          border-color: $blue-03;
          background-color: $blue-02;
        }
      }
    }

  }

}