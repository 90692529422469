/**
 * contact
 */
.contact {
  .avatar {
    flex: 0 0 12rem;
  }

  &__name {
    font-size: 2.4rem;
    line-height: 1;
    margin-bottom: 1.2rem;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }

  &__post {
    font-size: 1.6rem;
    line-height: 1.5;
    color: $grey-05;
    margin-bottom: 1.2rem;

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  &__phone {
    font-size: 1.6rem;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__mail a {
    font-size: 1.6rem;
    color: $red;
    text-decoration: underline;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
}
