/**
 * program-list.scss
 */
.program-list {
  &__day {
    font-size: 3.2rem;
    margin-bottom: 4.8rem;

    @media (max-width: 768px){
      font-size: 24px;
      margin-bottom: 48px;
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;
  }

  &__period {
    line-height: 1;

    @media (max-width: 768px){
      &.content_title {
        font-size: 18px;
        margin-bottom: 36px;
      }
    }
  }

  &__subtitle {
    text-transform: uppercase;

    @media (max-width: 768px){
      font-size: 16px;
    }
  }

  &__content {
    margin-bottom: 1.6rem;
    font-size: 1.8rem;
    line-height: 1.3;
    margin-left: 4.8rem;

    @media (max-width: 768px){
      margin-bottom: 16px;
      font-size: 16px;
      margin-left: 48px;
    }
  }

  &__content  &__content {
    margin-bottom: 0;;
  }

  &__number {
    font-weight: 700;
  }
}