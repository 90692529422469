/**
 * acceleration
 */
.section__acceleration {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  h2 {
    font-size: 3.2rem;
    font-weight: 700;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  &-description-text {
    font-size: 1.8rem;
    line-height: 1.75;

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 24px;

      p {
        margin-bottom: 15px;
      }
    }
  }

  .list_dotted li {
    font-size: 1.8rem;
    line-height: 1.75;

    &:before {
      background-color: $text;
      top: 1.3rem;
    }

    @media (max-width: 768px) {
      font-size: 16px;

      &:before {
        top: 11px;
      }
    }
  }

  .animated-container {
    transition: .5s ease-in-out;
    opacity: 0;
    transform: translateY(20rem);
  }

  .active {
    .animated-container {
      opacity: 1;
      transform: translateY(0);
      animation-name: animation-container;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      animation-duration: .5s;
    }
  }

  &-tag {

    .p-24 {
      @media (max-width: 540px) {
        padding: 16px;
      }
    }
    &-img {
      height: 22rem;

      @media (max-width: 768px) {
        height: 220px;
      }
    }

    &-title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 1;
      margin-bottom: 3.2rem;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 540px) {
        line-height: 1.5;
      }
    }

    .link-target:hover:before {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &-key {
    @media (max-width: 768px) {
      flex-wrap: nowrap;
      margin-right: -15px;
      margin-left: -15px;
      padding-left: 2px;
      padding-right: 30px;
      overflow-y: hidden;
      overflow-x: auto;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */

      &::-webkit-scrollbar {
        background: transparent; /* Chrome/Safari/Webkit */
        width: 0;
        height: 0;
      }
    }

    @media (max-width: 540px) {
      padding-left: 8px;
      padding-right: 24px;
    }

    .container-grid__item {
      flex: 0 0 33.333%;
      max-width: 33.333%;

      @media (max-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media (max-width: 540px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    svg {
      margin-top: 4.8rem;
      width: 7.2rem;
      height: 7.2rem;

      @media (max-width: 768px) {
        margin-top: 48px;
        width: 48px;
        height: 48px;
      }
    }

    h2 {
      margin: 2.4rem 0 1.2rem;

      @media (max-width: 768px) {
        font-size: 24px;
        margin: 24px 0 12px;
      }
    }

    .subtitle {
      font-size: 1.8rem;
      line-height: 1.75;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  &-target {
    padding: 8rem 0;

    .item-content {
      font-size: 1.8rem;
      line-height: 1.75;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    @media (max-width: 540px) {
      padding-bottom: 32vh;

      &.p-0 {
        padding-bottom: 20vh !important;
      }
    }

    &-img {
      height: 67.2rem;
      margin-top: -33.6rem;
      position: relative;
      z-index: 1;

      @media (max-width: 768px) {
        height: 52vh;
        margin-top: -30vh;
      }

      &_2 {
        @media (max-width: 768px) {
          height: 37vh;
          margin-top: -18vh;
        }
      }
    }
  }

  #msp &-target {
    @media (max-width: 540px) {

      &.p-0 {
        padding-bottom: 10px !important;
      }
    }
  }

  &-premise {
    margin: 0 0 13rem;

    .section__subsection-wrapper {
      padding: 0 0 19rem;

      @media (max-width: 540px) {
        padding-bottom: 64px;
      }
    }

    h2 {
      color: $white;
      max-width: 57rem;
    }

    @media (max-width: 540px) {
      .align-items-end .col-sm-6:last-child {
        order: -1;
        margin-bottom: 32px;
      }
    }

    .notice {
      h2,
      &__content {
        color: $white;
      }
    }

    .item-content {
      color: $white;
      font-size: 1.8rem;
      line-height: 1.75;
      max-width: 41rem;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 1.75;
        max-width: 100%;

        br {
          display: none;
        }
      }
    }

    &-grid {
      margin-top: 7.2rem;

      svg {
        width: 7.2rem;
        height: 7.2rem;

        @media (max-width: 768px) {
          width: 48px;
          height: 48px;
        }
      }

      h2 {
        margin-bottom: 3.6rem;
        margin-top: 2.6rem;

        @media (max-width: 768px) {
          max-width: 100%;
          margin-top: 25px;
        }
      }
    }

    .container-grid__item {
      flex: 0 0 33.333%;
      max-width: 33.333%;

      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 60px;
      }
    }
  }

  &-content {
    margin-bottom: 10rem;

    @media (max-width: 540px) {
      margin-bottom: 70px
    }

    h2.mb-5 {
      margin-bottom: 56px;
    }

    &-steps {
      overflow: hidden;
      max-width: 90rem;

      @media (max-width: 768px) {
        max-width: 100%;
      }
    }

    &-step {
      position: relative;
      padding-left: 5rem;
      padding-bottom: 5.6rem;

      @media (max-width: 768px) {
        padding-left: 50px;
        padding-bottom: 72px;
      }

      &:before {
        width: .3rem;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $red;
        content: '';

        @media (max-width: 768px) {
          width: 3px;
        }
      }

      &:last-child {
        &:before {
          width: .3rem;
          height: 3.5rem;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $red;
          content: '';

          @media (max-width: 768px) {
            width: 3px;
            height: 30px;
          }
        }
      }

      &-wrapper {
        padding: 1.2rem;
        transition: .3s ease-in-out;

        @media (max-width: 768px) {
          padding: 12px;
        }

        &:hover {
          background-color: $white;
        }
      }

      h2 {
        margin-bottom: 1.6rem;
        position: relative;

        &:before {
          width: 3.3rem;
          height: .3rem;
          position: absolute;
          left: -5.9rem;
          top: 2rem;
          background-color: $red;
          content: '';

          @media (max-width: 768px) {
            width: 33px;
            height: 3px;
            left: -59px;
            top: 15px;
          }
        }

        &:after {
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 50%;
          position: absolute;
          left: -2.8rem;
          top: 1.6rem;
          background-color: $red;
          content: '';

          @media (max-width: 768px) {
            width: 12px;
            height: 12px;
            left: -28px;
            top: 11px;
          }
        }
      }

      .sub-title {
        font-size: 2.4rem;
        line-height: 1;
        margin-bottom: 2.4rem;
        font-weight: 700;

        @media (max-width: 768px) {
          font-size: 16px;
          margin-bottom: 24px;
          line-height: 1.5;
        }
      }

      .item-content {
        font-size: 1.8rem;
        line-height: 1.75;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }

  &-resume {
    padding: 0 0 32rem;
    margin-bottom: -9rem;

    @media (max-width: 540px) {
      padding: 0 0 120px;
    }

    &_2 {
      padding-bottom: 14rem;
    }

    .section__subsection-wrapper {
      padding-top: 16rem;

      @media (max-width: 768px) {
        padding-top: 72px;
      }
    }

    &-title {
      line-height: 1;
      color: $white;
      margin-bottom: 10rem;
    }

    &-grid {
      @media (max-width: 768px) {

      }
    }

    .container-grid__item {
      flex: 0 0 25%;
      max-width: 25%;
      min-height: 36rem;

      @media (max-width: 768px) {
        flex: 0 0 50%;
        max-width: 50%;
        min-height: 36rem;
      }

      @media (max-width: 540px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .container-grid_3 {
      .container-grid__item {
        flex: 0 0 33.333%;
        max-width: 33.333%;

        @media (max-width: 768px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media (max-width: 540px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    svg {
      margin: 5.6rem 0 2.4rem;

      @media (max-width: 768px) {
        width: 48px;
        height: 48px;
        margin: 56px 0 24px;
      }
    }

    .item-content {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.3;
      color: $blue-03;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  &-tasks {
    margin-bottom: 12rem;

    &-grid {
      margin-bottom: 5.6rem;
    }

    .container-grid__item {
      flex: 0 0 33.333%;
      max-width: 33.333%;

      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    svg {
      margin: 5.6rem 0 2.4rem;
      width: 7.2rem;
      height: 7.2rem;

      @media (max-width: 768px) {
        margin: 56px 0 24px;
        width: 48px;
        height: 48px;
      }
    }
    h2 {
      line-height: 1;
      margin-bottom: 1.2rem;

      @media (max-width: 768px) {
        margin-bottom: 12px;
      }
    }
    .item-content {
      font-size: 1.8rem;
      line-height: 1.75;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  &-conditions {
    margin-top: 12rem;
  }

  &-feedbacks {
    margin-bottom: 12rem;

    .container-grid__item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &-feedback {
    &-title {
      font-size: 1.6rem;
      font-weight: 700;

      @media (max-width: 768px){
        font-size: 18px;
      }
    }
    &-content {
      font-size: 1.6rem;

      @media (max-width: 768px){
        font-size: 16px;
      }
    }
    .avatar {
      flex: 0 0 9.6rem;

      @media (max-width: 768px) {
        height: auto;
      }
    }
  }

  &-learn {
    margin-bottom: -9rem;

    .section__subsection-wrapper {
      padding: 16rem 0 22rem;
    }

    .section__acceleration-learn-title {
      color: $white;
    }
  }

  &-contacts-grid {
    .container-grid__item {
      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

@keyframes animation-container {
  0% {
    opacity: 0;
    transform: translateY(20rem);
  }
  80% {
    opacity: 0;
    transform: translateY(20rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}