/**
 * article.scss
 */
.section__article {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  &-content {
    @media screen and (min-width: 1200px) {
      margin-right: 9rem;
    }
  }

  .title-with-icon_menu .title-with-icon__title a {
    margin-left: -6.4rem;
    padding-left: 6.4rem;
    white-space: nowrap;
    color: $text;

    @media (max-width: 768px){
      margin-left: -48px;
      width: auto;
      padding-left: 42px;
      //text-indent: -10000px;
    }

    @media (max-width: 540px){
      padding-left: 48px;
    }

    &:before {
      opacity: 1;
      bottom: -5px;
      left: 6.4rem;

      @media (max-width: 768px){
        left: 42px;
      }

      @media (max-width: 540px){
        left: 48px;
      }
    }
  }

  .content_title {
    line-height: 1.1;
  }

  .section__library-doc-title {
    font-size: 2.4rem;
    line-height: 1;
  }

  .notice .content_subtitle {
    line-height: 1.3;

    @media (max-width: 540px){
      font-size: 16px;
      line-height: 1.5;
    }
  }

  @media (max-width: 768px){
    .content_18 {
      font-size: 16px;
      line-height: 1.75;
    }

    .section__library-doc-info {
      font-size: 16px;
    }

    .section__calendar-search-wrapper {
      margin-top: -5px;

      input {
        border-bottom-width: 2px;
      }
    }
  }

  .section__calendar-search {

    &-wrapper {
      @media (max-width: 540px) {
        margin-top: 0;
      }
    }
    @media (max-width: 540px) {
      position: absolute;
      width: 33px;
      height: 32px;
      overflow: hidden;
      right: 15px;
      margin-top: 0;
      transition: .3s ease-in-out;

      input {
        opacity: 0;
        padding-top: 7px;
      }
    }
  }

  .active {
    @media (max-width: 540px) {
      .section__calendar-search {
        width: calc(100vw - 72px);

        input {
          opacity: 1;
          padding-left: 29px;
          background-position: 0 6px;
          padding-right: 86px;
        }

        .search-trigger {
          display: none !important;
        }

        &-actions {
          right: 0;
        }
      }

      .title-with-icon__title {
        opacity: 0;
      }
    }
  }

  .search-trigger {
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background: no-repeat 50% 50% $blue-03 url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.4733 13.5266L11.9999 11.0733C12.96 9.87621 13.4249 8.3568 13.2991 6.82747C13.1733 5.29814 12.4664 3.87512 11.3236 2.85103C10.1808 1.82694 8.68914 1.27961 7.15522 1.32158C5.6213 1.36356 4.16175 1.99164 3.0767 3.0767C1.99164 4.16175 1.36356 5.6213 1.32158 7.15522C1.27961 8.68914 1.82694 10.1808 2.85103 11.3236C3.87512 12.4664 5.29814 13.1733 6.82747 13.2991C8.3568 13.4249 9.87621 12.96 11.0733 11.9999L13.5266 14.4533C13.5886 14.5158 13.6623 14.5654 13.7436 14.5992C13.8248 14.633 13.9119 14.6505 13.9999 14.6505C14.0879 14.6505 14.1751 14.633 14.2563 14.5992C14.3376 14.5654 14.4113 14.5158 14.4733 14.4533C14.5934 14.329 14.6606 14.1628 14.6606 13.9899C14.6606 13.817 14.5934 13.6509 14.4733 13.5266ZM7.33327 11.9999C6.41029 11.9999 5.50804 11.7262 4.74061 11.2135C3.97318 10.7007 3.37504 9.97185 3.02183 9.11913C2.66862 8.2664 2.57621 7.32809 2.75627 6.42285C2.93634 5.5176 3.38079 4.68608 4.03344 4.03344C4.68608 3.38079 5.5176 2.93634 6.42285 2.75627C7.32809 2.57621 8.2664 2.66862 9.11913 3.02183C9.97185 3.37504 10.7007 3.97318 11.2135 4.74061C11.7262 5.50804 11.9999 6.41029 11.9999 7.33327C11.9999 8.57095 11.5083 9.75793 10.6331 10.6331C9.75793 11.5083 8.57095 11.9999 7.33327 11.9999Z' fill='white'/%3E%3C/svg%3E");
  }


  @media (max-width: 540px) {
    .section__library-doc-category {
      font-size: 16px;
    }

    .section__library-doc-title {
      font-size: 16px;
      line-height: 1.5;
    }

    .section__library-doc-title {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}