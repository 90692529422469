/**
 * authorization.scss
 */
.log-in {
  .title-with-icon__icon_login {
    background-image: url(/pic/icon-login.svg);
  }
}

.restore-pass {
  .title-with-icon__icon_login {
    background-image: url(/pic/icon-restore-password.svg);
  }

  .modal-content {
    min-height: 40rem;

    @media (max-width: 768px) {
      min-height: 420px;
    }

    @media (max-width: 540px) {
      min-height: 380px;
    }
  }
}

.registration {
  @media (min-width: 769px) {
    width: 120.9rem;
    max-width: 120.9rem;
  }

  .title-with-icon__icon_login {
    background-image: url(/pic/icon-registration.svg);
  }

  .modal-content {
    min-height: 52rem;

    @media (max-width: 768px) {

    }
  }

  @media (max-width: 768px) {
    .container-grid {
      .grid-last-name,
      .grid-email,
      .grid-pass,
      .grid-pass-2,
      .grid-descr {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .grid-email,
      .grid-pass {
        padding-top: 24px;

        @media (max-width: 540px) {
          padding-top: 32px;
        }
      }

      .grid-first-name,
      .grid-patronic-name,
      .grid-phone,
      .grid-city,
      .grid-region {
        flex: 0 0 50%;
        max-width: 50%;

        @media (max-width: 540px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  .form-description {
    padding-left: 12px;
  }
}

.letter {

  .title-with-icon__icon_login {
    background-image: url(/pic/icon-letter.svg);
  }

  .notice .list_dotted li:before {
    top: 11px;
  }
}

.modal-letter-success__notice {
  background-color: $grey-06;
  margin-top: -4rem;

  @media (max-width: 768px) {
    margin-top: -40px;
  }

  @media (max-width: 540px) {
    margin-top: -5px;
  }

}

.modal-authorization {
  .title-with-icon__title {
    position: relative;

    span {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .3s;
      white-space: nowrap;

      &:not(.active) {
        opacity: 0;
      }
    }
  }

  .checkbox input {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -.6rem;

    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 768px) {
    .content_18 {
      font-size: 16px;
    }
  }
}

.modal-footer-authorization,
.modal-footer-restore {
  @media (max-width: 540px) {
    .col {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 50px;
    }
    .row {
      justify-content: flex-end;
    }
  }
}

.fp-viewing-footer {
  &.authorization-bg {


    .modal-open {
      background-color: $blue-03;
    }

    .main .section:not(.section__footer),
    .main .section__footer .section__wrapper {
      display: none;
    }

    .section__footer {
      background-image: url("data:image/svg+xml,%3Csvg width='759' height='670' viewBox='0 0 759 670' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M498.684 36.9774L508.179 102.991L632.281 85.2906L0 715.859L47.3725 763L693.023 119.055V312.57H759V0L498.684 36.9774Z' opacity='0.1' fill='%23ffffff'/%3E%3C/svg%3E");

      @media (max-width: 768px) {
        height: 100vh;
        background-position: 87% 100%;
        background-size: 50vh;
      }
    }
    .header__navigation a {
      color: #fff;
    }
    .header__navigation ul li.level-1.active > a {
      color: #fff;
    }
    .header__navigation ul ul li {
      & > a {
        color: #002E5E;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.authorization-bg {
  .modal-backdrop {
    opacity: 0;
  }
}


.register-sms {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: .3s ease-in-out;
  padding-top: 0;

  &.active {
    height: auto;
    opacity: 1;
    padding-top: 2.4rem;
  }
}

.modal-register-footer_sms {
  padding: 4rem 3.2rem;
  background-color: $blue-03;

  .btn-outline-primary:not(:hover):not(:focus) {
    color: $white;
    border-color: $white;
  }

  .form-control {
    width: 4.8rem;
    height: 4.8rem;
    margin-right: 1.6rem;
    background-image: none !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 2.4rem;
    text-align: center;

    @media (max-width: 768px) {
      width: 48px;
      height: 48px;
      margin-right: 16px;
      font-size: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  span {
    color: $white;
    margin-right: 1.6rem;

    @media (max-width: 768px) {
      margin-right: 16px;
      font-size: 16px;
    }
  }

  @media (max-width: 768px) {
    .btn {
      white-space: nowrap;
    }
    .row {
      justify-content: flex-start;
    }

    .link-target {
      margin-top: 24px;

      span {
        margin: 0;
      }

      &:before {
        background-color: $blue-02;
      }
    }
  }

  @media (max-width: 695px) {
    .btn {
      margin-bottom: 16px;
    }
    .row {
      justify-content: center;
    }
    .col {
      text-align: center;
      margin-bottom: 10px;
    }
    .d-flex {
      flex-flow: wrap;
      justify-content: center;
      margin-bottom: 10px;

      span {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin-right: 0;
      }
    }
  }
}

.modal-register-conditions-sms:not(.active) {
  transition: .3s ease-in-out;

  &:not(.active) {
    height: 0;
    overflow: hidden;
    opacity: 0;
    margin: 0 !important;
    padding: 0;
  }
}

.modal-letter-success {
  .content_subtitle {
    @media (max-width: 768px) {
      font-size: 16px;
      margin-top: 5px;
    }
  }
}

.restore-notice {
  transition: .3s ease-in-out;

  &:not(.active) {
    height: 0;
    overflow: hidden;
    opacity: 0;
    margin: 0 !important;
    padding: 0;
  }
}

.register-notice {
  transition: .3s ease-in-out;

  &:not(.active) {
    height: 0;
    overflow: hidden;
    opacity: 0;
    margin: 0 !important;
    padding: 0;
  }
}
