
.modal-dialog {
  max-width: 840px;
}
.modal-footer {
  padding-top: 2rem;
  button {
    &:first-child {
      margin-right: 3.2rem;
    }
  }
}
