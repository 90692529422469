/**
 * pager.scss
 */
.pager {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 540px){
    justify-content: flex-start;
    width: 100%;
  }

  &-items {
    display: flex;

    @media (max-width: 540px){
      justify-content: space-between;
      width: 100%;
    }
  }

  &-prev,
  &-next {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: 3px solid $blue-03;
    margin: 0 1.2rem;
    background-color: transparent;
    box-shadow: 0 1.6rem 2.4rem rgba($black, 0.08), 0 0.2rem 0.6rem rgba($black, 0.04), 0 0 0.1rem rgba($black, 0.04);
    background: no-repeat 50% 50% url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z' fill='%23002E5E'/%3E%3C/svg%3E");
    background-size: 2.4rem auto;

    @media (max-width: 768px){
      width: 48px;
      height: 48px;
      background-size: 24px auto;
    }

    @media (max-width: 540px){
      width: 32px;
      height: 32px;
      border-width: 2px;
      background-size: 16px auto;
    }

    &:hover {
      background-color: $blue-03;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z' fill='%23ffffff'/%3E%3C/svg%3E");
    }
    &.disabled {
      border: 3px solid #BDBDBD;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.92 11.6202C17.8724 11.4974 17.801 11.3853 17.71 11.2902L12.71 6.29019C12.6168 6.19695 12.5061 6.12299 12.3842 6.07253C12.2624 6.02207 12.1319 5.99609 12 5.99609C11.7337 5.99609 11.4783 6.10188 11.29 6.29019C11.1968 6.38342 11.1228 6.49411 11.0723 6.61594C11.0219 6.73776 10.9959 6.86833 10.9959 7.00019C10.9959 7.26649 11.1017 7.52188 11.29 7.71019L14.59 11.0002H7C6.73478 11.0002 6.48043 11.1055 6.29289 11.2931C6.10536 11.4806 6 11.735 6 12.0002C6 12.2654 6.10536 12.5198 6.29289 12.7073C6.48043 12.8948 6.73478 13.0002 7 13.0002H14.59L11.29 16.2902C11.1963 16.3831 11.1219 16.4937 11.0711 16.6156C11.0203 16.7375 10.9942 16.8682 10.9942 17.0002C10.9942 17.1322 11.0203 17.2629 11.0711 17.3848C11.1219 17.5066 11.1963 17.6172 11.29 17.7102C11.383 17.8039 11.4936 17.8783 11.6154 17.9291C11.7373 17.9798 11.868 18.006 12 18.006C12.132 18.006 12.2627 17.9798 12.3846 17.9291C12.5064 17.8783 12.617 17.8039 12.71 17.7102L17.71 12.7102C17.801 12.6151 17.8724 12.5029 17.92 12.3802C18.02 12.1367 18.02 11.8636 17.92 11.6202Z' fill='%23bdbdbd'/%3E%3C/svg%3E");
    }

  }
  &-prev {
    transform: rotate(180deg);
  }

  &-next {
    margin-right: 0;
  }

  &-first,
  &-last {
    width: 2rem;
    height: 4.8rem;
    margin: 0 1.2rem;
    background-color: transparent;
    background: no-repeat 50% 50% url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.71 7.29L11.71 3.29C11.6168 3.19676 11.5061 3.1228 11.3842 3.07234C11.2624 3.02188 11.1319 2.99591 11 2.99591C10.8681 2.99591 10.7376 3.02188 10.6158 3.07234C10.4939 3.1228 10.3832 3.19676 10.29 3.29C10.1968 3.38324 10.1228 3.49393 10.0723 3.61575C10.0219 3.73757 9.99591 3.86814 9.99591 4C9.99591 4.13186 10.0219 4.26243 10.0723 4.38425C10.1228 4.50607 10.1968 4.61676 10.29 4.71L12.59 7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H12.59L10.29 11.29C10.1963 11.383 10.1219 11.4936 10.0711 11.6154C10.0203 11.7373 9.9942 11.868 9.9942 12C9.9942 12.132 10.0203 12.2627 10.0711 12.3846C10.1219 12.5064 10.1963 12.617 10.29 12.71C10.383 12.8037 10.4936 12.8781 10.6154 12.9289C10.7373 12.9797 10.868 13.0058 11 13.0058C11.132 13.0058 11.2627 12.9797 11.3846 12.9289C11.5064 12.8781 11.617 12.8037 11.71 12.71L15.71 8.71C15.801 8.6149 15.8724 8.50275 15.92 8.38C16.02 8.13654 16.02 7.86346 15.92 7.62C15.8724 7.49725 15.801 7.3851 15.71 7.29ZM19 0C18.7348 0 18.4804 0.105357 18.2929 0.292893C18.1054 0.48043 18 0.734784 18 1V15C18 15.2652 18.1054 15.5196 18.2929 15.7071C18.4804 15.8946 18.7348 16 19 16C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0Z' fill='%23002E5E'/%3E%3C/svg%3E");
    background-size: 2rem auto;

    @media (max-width: 540px){
      width: 16px;
      height: 32px;
      background-size: 16px auto;
    }

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.71 7.29L11.71 3.29C11.6168 3.19676 11.5061 3.1228 11.3842 3.07234C11.2624 3.02188 11.1319 2.99591 11 2.99591C10.8681 2.99591 10.7376 3.02188 10.6158 3.07234C10.4939 3.1228 10.3832 3.19676 10.29 3.29C10.1968 3.38324 10.1228 3.49393 10.0723 3.61575C10.0219 3.73757 9.99591 3.86814 9.99591 4C9.99591 4.13186 10.0219 4.26243 10.0723 4.38425C10.1228 4.50607 10.1968 4.61676 10.29 4.71L12.59 7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H12.59L10.29 11.29C10.1963 11.383 10.1219 11.4936 10.0711 11.6154C10.0203 11.7373 9.9942 11.868 9.9942 12C9.9942 12.132 10.0203 12.2627 10.0711 12.3846C10.1219 12.5064 10.1963 12.617 10.29 12.71C10.383 12.8037 10.4936 12.8781 10.6154 12.9289C10.7373 12.9797 10.868 13.0058 11 13.0058C11.132 13.0058 11.2627 12.9797 11.3846 12.9289C11.5064 12.8781 11.617 12.8037 11.71 12.71L15.71 8.71C15.801 8.6149 15.8724 8.50275 15.92 8.38C16.02 8.13654 16.02 7.86346 15.92 7.62C15.8724 7.49725 15.801 7.3851 15.71 7.29ZM19 0C18.7348 0 18.4804 0.105357 18.2929 0.292893C18.1054 0.48043 18 0.734784 18 1V15C18 15.2652 18.1054 15.5196 18.2929 15.7071C18.4804 15.8946 18.7348 16 19 16C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0Z' fill='%231C75BC'/%3E%3C/svg%3E");
    }

    &.disabled {
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.71 7.29L11.71 3.29C11.6168 3.19676 11.5061 3.1228 11.3842 3.07234C11.2624 3.02188 11.1319 2.99591 11 2.99591C10.8681 2.99591 10.7376 3.02188 10.6158 3.07234C10.4939 3.1228 10.3832 3.19676 10.29 3.29C10.1968 3.38324 10.1228 3.49393 10.0723 3.61575C10.0219 3.73757 9.99591 3.86814 9.99591 4C9.99591 4.13186 10.0219 4.26243 10.0723 4.38425C10.1228 4.50607 10.1968 4.61676 10.29 4.71L12.59 7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H12.59L10.29 11.29C10.1963 11.383 10.1219 11.4936 10.0711 11.6154C10.0203 11.7373 9.9942 11.868 9.9942 12C9.9942 12.132 10.0203 12.2627 10.0711 12.3846C10.1219 12.5064 10.1963 12.617 10.29 12.71C10.383 12.8037 10.4936 12.8781 10.6154 12.9289C10.7373 12.9797 10.868 13.0058 11 13.0058C11.132 13.0058 11.2627 12.9797 11.3846 12.9289C11.5064 12.8781 11.617 12.8037 11.71 12.71L15.71 8.71C15.801 8.6149 15.8724 8.50275 15.92 8.38C16.02 8.13654 16.02 7.86346 15.92 7.62C15.8724 7.49725 15.801 7.3851 15.71 7.29ZM19 0C18.7348 0 18.4804 0.105357 18.2929 0.292893C18.1054 0.48043 18 0.734784 18 1V15C18 15.2652 18.1054 15.5196 18.2929 15.7071C18.4804 15.8946 18.7348 16 19 16C19.2652 16 19.5196 15.8946 19.7071 15.7071C19.8946 15.5196 20 15.2652 20 15V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0Z' fill='%23BDBDBD'/%3E%3C/svg%3E");
    }
  }
  &-first {
    transform: rotate(180deg);
  }
  &-last {
    margin-left: 2.4rem;
  }

  &-item {
    margin: 0 1.2rem;
    display: block;
    position: relative;
    font-size: 2.4rem;
    padding-top: 4rem;
    color: $text;
    text-align: center;

    @media (max-width: 768px){
      font-size: 16px;
      padding-top: 48px;
      margin: 0 12px;
    }

    @media (max-width: 540px){
      margin: 0 7px;
      padding-top: 40px;
    }

    &:before {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      border: 3px solid $blue-03;
      background: transparent;
      position: absolute;
      content: '';
      left: 50%;
      top: 0;
      transform: translate(-50%, 1.2rem);

      @media (max-width: 768px){
        width: 24px;
        height: 24px;
        top: 7px;
      }

      @media (max-width: 540px){
        top: -2px;
      }
    }

    &:hover {
      text-decoration: none;

      &:before {
        background-color: rgba($blue-03, 0.4);
      }
    }
  }

  &-current {
    &:before {
      border-color: $red;
      background-color: $red;
    }
  }
}