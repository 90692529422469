/**
 * dropdown
 */
.dropdown {
  &-toggle {
    padding-right: 2rem;
    position: relative;
    color: $blue-02;

    @media (max-width: 768px) {
      padding-right: 20px;
    }

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      border: 0;
      width: 1.2rem;
      height: .8rem;
      background-size: contain;
      background-repeat: no-repeat;
      transition: .3s ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9999 1.17C10.8126 0.983753 10.5591 0.879211 10.2949 0.879211C10.0308 0.879211 9.77731 0.983753 9.58995 1.17L5.99995 4.71L2.45995 1.17C2.27259 0.983753 2.01913 0.879211 1.75495 0.879211C1.49076 0.879211 1.23731 0.983753 1.04995 1.17C0.95622 1.26297 0.881826 1.37357 0.831057 1.49543C0.780288 1.61729 0.75415 1.74799 0.75415 1.88C0.75415 2.01202 0.780288 2.14272 0.831057 2.26458C0.881826 2.38644 0.95622 2.49704 1.04995 2.59L5.28995 6.83C5.38291 6.92373 5.49351 6.99813 5.61537 7.04889C5.73723 7.09966 5.86794 7.1258 5.99995 7.1258C6.13196 7.1258 6.26267 7.09966 6.38453 7.04889C6.50638 6.99813 6.61699 6.92373 6.70995 6.83L10.9999 2.59C11.0937 2.49704 11.1681 2.38644 11.2188 2.26458C11.2696 2.14272 11.2957 2.01202 11.2957 1.88C11.2957 1.74799 11.2696 1.61729 11.2188 1.49543C11.1681 1.37357 11.0937 1.26297 10.9999 1.17Z' fill='%231C75BC'/%3E%3C/svg%3E ");

      @media (max-width: 768px) {
        width: 7px;
        height: 4px;
      }
    }

    &[aria-expanded="true"]{
      color: $blue-03;

      &:after {
        transform: translateY(-50%) scale(-1,-1);
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9999 1.17C10.8126 0.983753 10.5591 0.879211 10.2949 0.879211C10.0308 0.879211 9.77731 0.983753 9.58995 1.17L5.99995 4.71L2.45995 1.17C2.27259 0.983753 2.01913 0.879211 1.75495 0.879211C1.49076 0.879211 1.23731 0.983753 1.04995 1.17C0.95622 1.26297 0.881826 1.37357 0.831057 1.49543C0.780288 1.61729 0.75415 1.74799 0.75415 1.88C0.75415 2.01202 0.780288 2.14272 0.831057 2.26458C0.881826 2.38644 0.95622 2.49704 1.04995 2.59L5.28995 6.83C5.38291 6.92373 5.49351 6.99813 5.61537 7.04889C5.73723 7.09966 5.86794 7.1258 5.99995 7.1258C6.13196 7.1258 6.26267 7.09966 6.38453 7.04889C6.50638 6.99813 6.61699 6.92373 6.70995 6.83L10.9999 2.59C11.0937 2.49704 11.1681 2.38644 11.2188 2.26458C11.2696 2.14272 11.2957 2.01202 11.2957 1.88C11.2957 1.74799 11.2696 1.61729 11.2188 1.49543C11.1681 1.37357 11.0937 1.26297 10.9999 1.17Z' fill='%23002E5E'/%3E%3C/svg%3E ");
      }
    }
  }

  &-item + &-item {
    margin: -0.2rem 0 0;
  }

  &-menu {
    left: -2.4rem !important;
    box-shadow: 0 1.6rem 2.4rem rgba($black, 0.08), 0 0.2rem 0.6rem rgba($black, 0.04), 0 0 0.1rem rgba($black, 0.04);
    background-color: $white;
    border-bottom-right-radius: 2.4rem;
    flex-direction: column;
    margin-top: 1rem;

    @media (max-width: 768px) {
      margin-top: 10px;
      left: -24px;
      border-bottom-right-radius: 24px;
    }


    .dropdown-item {
      padding: 2.4rem 5.6rem 2.4rem 2.4rem;
      text-transform: uppercase;
      color: $text;
      font-size: 1.6rem;
      position: relative;

      @media (max-width: 768px) {
        padding: 24px 56px 24px 24px;
        font-size: 16px;
      }

      &:hover {
        color: $white;
        background-color: $primary;
        text-decoration: none;
      }

      &:not(:last-child) {

        &:before {
          content: '';
          position: absolute;
          left: 2.4rem;
          right: 2.4rem;
          bottom: 0;
          border-top: 0.2rem solid $primary;

          @media (max-width: 768px) {
            left: 24px;
            right: 24px;
          }
        }
      }

      &:last-child {
        border-bottom-right-radius: 2.4rem;
      }

      &.active {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7099 1.21C14.617 1.11627 14.5064 1.04188 14.3845 0.991107C14.2627 0.940338 14.132 0.9142 13.9999 0.9142C13.8679 0.9142 13.7372 0.940338 13.6154 0.991107C13.4935 1.04188 13.3829 1.11627 13.29 1.21L5.83995 8.67L2.70995 5.53C2.61343 5.43676 2.49949 5.36345 2.37463 5.31424C2.24978 5.26504 2.11645 5.24091 1.98227 5.24323C1.84809 5.24555 1.71568 5.27428 1.5926 5.32777C1.46953 5.38126 1.35819 5.45848 1.26495 5.555C1.17171 5.65152 1.0984 5.76546 1.04919 5.89032C0.999989 6.01517 0.975859 6.1485 0.97818 6.28268C0.980502 6.41686 1.00923 6.54927 1.06272 6.67234C1.11622 6.79542 1.19343 6.90676 1.28995 7L5.12995 10.84C5.22291 10.9337 5.33351 11.0081 5.45537 11.0589C5.57723 11.1097 5.70794 11.1358 5.83995 11.1358C5.97196 11.1358 6.10267 11.1097 6.22453 11.0589C6.34639 11.0081 6.45699 10.9337 6.54995 10.84L14.7099 2.68C14.8115 2.58636 14.8925 2.4727 14.9479 2.3462C15.0033 2.21971 15.0319 2.0831 15.0319 1.945C15.0319 1.8069 15.0033 1.67029 14.9479 1.54379C14.8925 1.41729 14.8115 1.30364 14.7099 1.21Z' fill='%23ED1B2F'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: calc(100% - 2.8rem) 50%;
        background-size: 1.6rem auto;
        background-color: transparent;
        color: $text;
        font-weight: 400;

        @media (max-width: 768px) {
          background-size: 16px auto;
          background-position: calc(100% - 28px) 50%;
        }

        &:hover {
          color: $white;
          background-color: $primary;
        }
      }
    }
  }
}
