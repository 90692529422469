/**
 * modal
 */

.modal-backdrop {
  z-index: 60;
}

.modal {

  &.fade &-dialog {
    transition: .3s ease-in-out;
  }

  z-index: 2000;

  &-header {
    text-align: left;
    position: relative;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      padding: 24px;
    }

    .close {
      padding: 0;
      margin: 0;
      font-size: 0;
      font-weight: 400;
      text-shadow: none;
      opacity: 1;
      height: 18px;
      width: 18px;
      overflow: hidden;
      text-indent: -10000px;
      outline: 0;
      background: url('/pic/icon-cross.svg') no-repeat 0 0;

      span {
        display: none;
      }
    }

    &-blue-bg {
      background-color: $blue-03;
      .title-with-icon__title {
        color: $white;
      }
      .close {
        background: url('/pic/icon-cross-white.svg') no-repeat 0 0;
      }
    }
    &-danger-bg {
      background-color: $danger;
      .title-with-icon__title {
        color: $white;
      }
      .close {
        background: url('/pic/icon-cross-white.svg') no-repeat 0 0;
      }
    }
  }

  &-body {
    padding: 0 4rem 2rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      padding: 0 24px 12px;
    }
  }

  &-footer {
    padding: 0 4rem 4rem;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      padding: 0 24px 24px;

    }
  }

  &-content {
    transition: .3s ease-in-out;
  }

  form {
    width: 100%;
  }

  &-authorization {
    width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
    align-items: flex-start;

    @media (min-width: 769px) {
      width: 68rem;
      max-width: 68rem;
      align-items: center;
    }

    @media (max-width: 575px) {
      margin: auto;
    }
  }

  &-authorization &-content {
    @media (max-width: 768px) {
      margin-top: 110px;
    }

    @media (max-width: 540px){
      margin-top: 96px;
    }
  }

  &-authorization &-header {
    padding-bottom: 7rem;

    @media (max-width: 768px) {
      padding: 48px 40px 78px;
    }

    @media (max-width: 540px){
      padding: 16px 16px 48px;
    }
  }

  &-authorization &-body {
    padding: 0 4rem 4rem;

    @media (max-width: 540px){
      padding: 0 16px 4px;
    }
  }

  &-authorization &-content {
    min-height: 58rem;

    @media (max-width: 768px) {
      min-height: 510px;
    }

    @media (max-width: 540px) {
      min-height: 425px;
    }


  }

  &-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: 1;
    animation-name: animation-form;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: .25s;

    &:not(.active) {
      display: none;
    }

    &__body {
      flex: 1 1 auto;
    }

    &__footer {
      padding-top: 2.4rem;
    }
  }
}

.authorization-bg {
  @media only screen and (min-device-width: 1024px) {
    .modal {
      z-index: 80;
    }
    .header {
      z-index: 1000;
    }
    .aside {
      z-index: -100;
    }
  }
}

@keyframes animation-form {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}