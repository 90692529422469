/**
 * author.scss
 */
.author {
  display: flex;
  margin-bottom: 2.4rem;
  padding: 2.4rem;
  background: $white;

  @media (max-width: 768px) {
    margin-bottom: 24px;
    padding: 24px;
  }

  @media (max-width: 540px) {
    flex-flow: wrap;
  }

  &__image {
    margin-right: 2.4rem;

    @media (max-width: 768px) {
      margin-right: 24px;
    }

    @media (max-width: 540px) {
      margin-bottom: 24px;
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;
    }
  }

  &__content {
    flex: 1;
  }

  &__name {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 2.4rem;

    @media (max-width: 768px) {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }

  &__data {
    margin-bottom: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.33;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 24px;
    }

    &-label {
      color: $blue-02;
    }
  }

  .show-more {
    display: inline-block;
    color: $blue-03;
    font-weight: 700;
    cursor: pointer;
  }
}