/**
 * about.scss
 */
.section__about {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  &-content {

    @media (min-width: 1250px){
      margin-right: 9rem;
    }

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 3.2rem;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 1;
      margin-bottom: 24px;
    }

    @media (max-width: 540px) {
      font-size: 16px;
    }
  }

  &-all {
    h2 {
      margin-bottom: 2.4rem;

      &:not(:first-child){
        margin-top: 1.2rem;
      }

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 1;
        margin-bottom: 24px;
      }
    }

    p {
      font-size: 1.8rem;
      line-height: 1.7;
      margin-bottom: 2rem;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 2;
        margin-bottom: 20px;
      }
    }

    .cite {
      margin-bottom: 2rem;
      padding: 2.4rem 4.8rem;
      background: $white;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 1.3;
      border-left: .8rem solid $red;

      @media (max-width: 768px) {
        margin-bottom: 24px;
        padding: 10px 24px;
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  &-course {
    margin-bottom: 2.4rem;
    padding: 2.4rem;
    background: $white;
    display: flex;

    @media (max-width: 768px){
      margin-bottom: 24px;
      padding: 24px;
    }

    @media (max-width: 540px) {
      padding: 16px;
      flex-flow: wrap;
    }

    &-img {
      flex: 0 0 26rem;
      max-width: 26rem;
      margin-right: 2.4rem;

      @media (max-width: 768px){
        max-width: 180px;
        margin-right: 24px;
        height: 144px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      @media (max-width: 540px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 24px;
        height: 160px;
      }

      img {
        max-width: 100%;
      }
    }

    &-content {
      flex-grow: 1;
    }

    &-title {
      line-height: 1;
      margin-bottom: 1.2rem;

      a {
        color: $text;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-text {
      color: $grey-05;
      font-size: 1.8rem;

      @media (max-width: 768px){
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  &-regions {
    h2 {
      line-height: 1.1;
      margin-bottom: 0;

      @media (max-width: 540px) {
        line-height: 1.5;
        margin-left: -15px;
      }
    }

    .container-grid_pad-scroll {
      padding-left: 16px;
      padding-right: 0;
    }

    .table-wrapper {
      margin-top: 10rem;

      @media (max-width: 540px) {
        margin-top: 24px;
      }
    }

    .ext-wrapper {
      margin-left: 0;
    }
  }

  .section__news-item {
    margin: 0;

    @media (max-width: 768px) {
      box-shadow: $box-shadow;
      transition: .3s ease-in-out;

      &:hover {
        box-shadow: $box-shadow-hover;
      }
    }
  }

  &-carousel {

    @media (max-width: 768px) {
      &-wrapper {
        margin: 0 -40% 0 -8px;
      }

      .item {
        padding: 0 8px 16px;
      }
    }
    @media (max-width: 540px) {
      &-wrapper {
        margin-right: -100%;
      }
    }

    .owl-stage-outer {
      margin-bottom: 2.4rem;
      box-shadow: $box-shadow;
      transition: .3s ease-in-out;

      &:hover {
        box-shadow: $box-shadow-hover;
      }

      @media (max-width: 768px) {
        box-shadow: none;

        &:hover {
          box-shadow: none;
        }
      }
    }

    .owl-stage,
    .owl-item,
    .item {
      display: flex;
      align-items: stretch;
    }

    .owl-nav {
      justify-content: flex-end;

      @media (max-width: 540px) {
        width: calc(100vw - 24px);
      }
    }
  }
}
