/**
 * calendar item
 */
.calendar-item {
  padding: 2.4rem;
  display: flex;
  background: $white;
  margin: 0 2rem 2.4rem;
  box-shadow: 0 1.6rem 2.4rem rgba($black, 0.08),
              0 0.2rem 0.6rem rgba($black, 0.04),
              0 0 0.1rem rgba($black, 0.04);

  @media (max-width: 767px){
    display: grid;
    grid-template-areas: "date image" "description image";
    grid-template-columns: 54% 1fr;
    position: relative;
  }

  &__date {
    display: flex;
    max-width: 20.1rem;
    flex: 0 0 20.1rem;
    padding-right: 2.4rem;
    flex-direction: column;

    @media (max-width: 767px){
      grid-area: date;
      max-width: 100%;
      flex: 0 0 100%;
    }

    &-num {
      font-weight: 300;
      font-size: 14rem;
      line-height: 1;
      letter-spacing: -0.08em;
      margin-top: -1.1rem;

      @media (max-width: 767px){
        font-size: 64px;
        margin-top: -1.5rem;
      }
    }

    &-month {
      flex: 1 0 auto;
      font-weight: 800;
      font-size: 2.2rem;
      text-transform: uppercase;

      span {
        display: block;
        transform: translateY(-.5rem);
      }
    }
  }

  &__location {
    padding-left: 2.8rem;
    background: url('/pic/icon-location.svg') no-repeat 0 50% / 1.6rem auto;
    font-size: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    @media (max-width: 767px){
      padding: 3px 5px 3px 24px;
      font-size: 10px;
      background-color: $white;
      background-position: 9px 5px;
      background-size: 8px auto;
      white-space: normal;
      display: inline-block;

      &-wrapper {
        position: absolute;
        bottom: 11px;
        right: 15px;
        left: calc(54% + 2.4rem + 10px);
      }
    }
  }

  &__image {
    width: 25%;
    margin: -2.4rem 0;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 767px){
      grid-area: image;
      grid-row: auto / span 2;
      width: auto;
      margin-right: -2.4rem;
      margin-left: 10px;
    }
  }

  &__data {
    display: flex;
    width: 50%;
    padding-left: 2.4rem;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 767px){
      grid-area: description;
      width: auto;
      padding-left: 0;
      margin-top: 12px;
    }
  }

  &__type {
    padding-left: 3.6rem;
    background: url('/pic/icon-type.svg') no-repeat 0 0 / auto 2.4rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    min-height: 2.4rem;
    margin-bottom: 2.4rem;

    &__anticris {
      background: url('/pic/icon-globe.svg') no-repeat 0 0 / auto 2.4rem;
      padding-left: 2.8rem;
      font-size: 1.6rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    @media (max-width: 767px){
      font-size: 12px;
      background-position: 0 3px;
    }
  }

  &__description {
    flex: 1 0 auto;
    font-size: 1.8rem;
    margin-bottom: 2rem;

    @media (max-width: 767px){
      font-size: 13px;
      margin-bottom: 12px;
    }
  }

  .link-target {
    padding-left: 1.2rem;
    margin-left: -1.2rem;


    &:hover{
      &:before {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}