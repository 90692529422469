
.block-member {
  .form-item_groupped > label + * {
    max-width: calc(100% - 18.4rem);
    flex: 0 0 calc(100% - 18.4rem);
  }
  &-wide {
    .form-item_groupped > label + * {
      max-width: calc(100% - 17rem);
      flex: 0 0 calc(100% - 17rem);
    }
    .sweet-calendar {
      width: 100%!important;
    }
  }
}
