/**
 * typography
 */

h1 {
  font-size: 4rem;
  line-height: 1.2;
  font-weight: 300;
  margin: 0;
}

h2 {
  font-size: 3rem;
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
}

h3 {
  font-size: 2.4rem;
  line-height: 1.3;
  font-weight: 400;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 24px;
  }
  @media (max-width: 540px) {
    font-size: 18px;
  }
}

.content-medium {
  font-weight: 500;
}

.content_12 {
  font-size: 1.2rem;
}

.content_14 {
  font-size: 1.4rem;
}

.content_18 {
  font-size: 1.8rem;
}

.content_20 {
  font-size: 2rem;
}

.content_22 {
  font-size: 2.2rem;
}

.content_40 {
  font-size: 4rem;
}
.width_100 {
  width: 100%;
}
.width_75 {
  width: 75%;
}
.width_80 {
  width: 80%;
}
.width_50 {
  width: 50%;
}

.text-blue {
  color: #1C75BC;
}
.text-red {
  color: #ED1B2F;
}
.text-blue-03 {
  color: $blue-03;
}
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-90 {
  transform: rotate(90deg);
}

.text-16 {
  font-size: 1.6rem;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}
.mb-32 {
  margin-bottom: 3.2rem;
  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
}
.mt-31 {
  margin-top: 3.2rem;
  @media (max-width: 768px) {
    margin-top: 32px;
  }
}
.toast-container {
  .toast {
    width: auto;
    .toast-title {
      font-size: 1.6rem;
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }
}
.text-success {
  color: $green-02!important;
}
.bg-success {
  background-color: rgba(39, 174, 96, 0.2)!important;
}
.text-danger {
  color: $red!important;
}
.bg-danger {
  background-color: rgba(237, 27, 47, 0.2)!important;
}
.text-grey {
  color: $grey-02!important;
}