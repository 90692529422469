.multiselect {
  &.invalid {
    .multiselect__tags {
      box-shadow: 0 0 0 1px $red inset!important;
    }
  }
  .multiselect__tag {
    background-color: $primary!important;
  }
  .multiselect__tag-icon {
    &:after {
      color: #fff!important;
    }
    &:hover {
      background: inherit!important;
    }
  }
  .multiselect__option--highlight {
    background-color: $primary!important;
  }
  .multiselect__tags {
    background: $grey-06!important;
    border: none!important;
    border-radius: 0!important;
    padding: 1.2rem 1.4rem!important;
    .multiselect__tag {
      margin-bottom: 0!important;
    }
  }
  .multiselect__placeholder {
    padding: 0!important;
    margin: 0!important;
  }
  .multiselect__select {
    &:before {
      border: none!important;
    }
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71018L2.45995 1.17018C2.27259 0.983927 2.01913 0.879386 1.75495 0.879386C1.49076 0.879386 1.23731 0.983927 1.04995 1.17018C0.95622 1.26314 0.881825 1.37374 0.831057 1.4956C0.780288 1.61746 0.75415 1.74817 0.75415 1.88018C0.75415 2.01219 0.780288 2.1429 0.831057 2.26476C0.881826 2.38661 0.95622 2.49722 1.04995 2.59018L5.28995 6.83018C5.38291 6.92391 5.49351 6.9983 5.61537 7.04907C5.73723 7.09984 5.86794 7.12598 5.99995 7.12598C6.13196 7.12598 6.26267 7.09984 6.38453 7.04907C6.50638 6.9983 6.61699 6.92391 6.70995 6.83018L10.9999 2.59018C11.0926 2.49674 11.166 2.38592 11.2157 2.26408C11.2655 2.14225 11.2907 2.01178 11.2899 1.88018C11.2907 1.74857 11.2655 1.61811 11.2157 1.49627C11.166 1.37443 11.0926 1.26362 10.9999 1.17018C10.8126 0.983927 10.5591 0.879386 10.2949 0.879386C10.0308 0.879386 9.77731 0.983927 9.58995 1.17018L5.99995 4.71018Z' fill='%231C75BC'/%3E%3C/svg%3E")!important;
    background-position: center center!important;
    background-repeat: no-repeat!important;
  }
}