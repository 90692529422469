/**
 * search.scss
 */
.section__search {
  transition: transform .9s cubic-bezier(0.65, 0.05, 0.36, 1);
  position: fixed;
  background: $white;
  //background: $body-bg;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);

  .header {
    position: absolute;
    background: $white;
  }

  .show-search & {
    transform: translateX(0);
  }

  .section__wrapper {
    max-height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px){
      padding-top: 142px;
    }

    @media (max-width: 540px){
      padding-top: 112px;
    }
  }

  &-form {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 7rem;

    &-clean {
      position: absolute;
      top: 48%;
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
      background: url('/pic/icon-cross-red.svg') no-repeat 50% 50% / contain;
      transform: translateY(-50%);
      transition: all .3s linear;
      opacity: 0;
      z-index: -1;
      cursor: pointer;

      .show-results & {
        opacity: 1;
        z-index: 1;
      }
    }
  }


  &-text {
    flex-grow: 1;
    height: 6.4rem;
    border-width: 0 0 3px;
    border-color: $blue-03;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: transparent;
    transition: all .3s linear;
    margin-right: 3.4rem;

    .show-results & {
      padding-left: 50px;
    }

    &:focus,
    &:active {
      outline: 0;
    }

    &::placeholder {
      color: $grey-04;
    }
  }

  &-submit {
    border: 0;
    background-color: transparent;
  }

  &-popular-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: wrap;

    li {
      margin: 0 3.5rem 2rem 0;
    }

    a {
      color: $blue-02;
      font-size: 1.8rem;
    }
  }

  &-data {
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;

    .show-results & {
      overflow: auto;
      overflow-x: hidden;
    }
  }

  &-popular {
    transition: transform .4s ease-in-out, opacity .5s ease-in-out;

    .show-results & {
      transform: translateY(100%);
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-results {
    transition: transform .4s ease-in-out, opacity .5s ease-in-out;
    transform: translateY(100%);
    opacity: 0;

    .show-results & {
      opacity: 1;
      transform: translateY(0);
      margin-right: 24px;
    }
  }

  &-item {
    font-size: 1.8rem;
    padding: 1.2rem 0 1.8rem;
    transition: .3s ease-in-out;

    &:hover {
      background-color: $grey-bg;
    }

    img {
      width: 2rem;
    }

    &-image {
      margin-top: .4rem;
    }
    &-date {
      color: $grey-04;
    }
    &-title {
      font-size: 2.4rem;
      font-weight: 700;

      a {
        color: $text;
      }
    }
    &-path {
      color: $grey-04;
      margin-bottom: 1.2rem
    }
    &-teaser {
      color: $text;
      margin-bottom: 1.2rem
    }
    &-address a {
      color: $blue-02;
    }
  }
}