@font-face {
  font-family: 'Circe';
  src: url('/fontes/Circe_Regular.woff') format('woff'),
  url('/fontes/Circe_Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/fontes/Circe-ExtraLight.eot');
  src: local('Circe ExtraLight'), local('Circe-ExtraLight'),
  url('/fontes/Circe-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('/fontes/Circe-ExtraLight.woff') format('woff'),
  url('/fontes/Circe-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/fontes/Circe-Thin.eot');
  src: local('Circe Thin'), local('Circe-Thin'),
  url('/fontes/Circe-Thin.eot?#iefix') format('embedded-opentype'),
  url('/fontes/Circe-Thin.woff') format('woff'),
  url('/fontes/Circe-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/fontes/Circe-Light.eot');
  src: local('Circe Light'), local('Circe-Light'),
  url('/fontes/Circe-Light.eot?#iefix') format('embedded-opentype'),
  url('/fontes/Circe-Light.woff') format('woff'),
  url('/fontes/Circe-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/fontes/Circe-Bold.eot');
  src: local('Circe Bold'), local('Circe-Bold'),
  url('/fontes/Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fontes/Circe-Bold.woff') format('woff'),
  url('/fontes/Circe-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/fontes/Circe_Bold.woff') format('woff'),
  url('/fontes/Circe_Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('/fontes/Circe-ExtraBold.eot');
  src: local('Circe ExtraBold'), local('Circe-ExtraBold'),
  url('/fontes/Circe-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/fontes/Circe-ExtraBold.woff') format('woff'),
  url('/fontes/Circe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}