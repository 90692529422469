/**
 * list.scss
 */
.list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &_dotted {
    li {
      padding-left: 3.4rem;
      position: relative;
      font-size: 1.8rem;
      margin-bottom: .5rem;
      line-height: 1.3;

      &:before {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: inherit;
        content: '';
        position: absolute;
        left: 0.9rem;
        top: 1rem;
      }

      @media (max-width: 768px){
        font-size: 16px;

        &:before {
          left: 7px;
          top: 11px;
        }
      }
    }
    &-blue {
      li {
        font-size: 1.8rem;
        line-height: 3.2rem;
        &:before {
          background: $blue-02!important;
        }
      }
    }
  }

  &_lined {

    li {
      position: relative;
      padding-left: 2rem;
      margin-bottom: .5rem;
      font-size: 1.6rem;
      color: $text;

      &:before {
        width: 1.5rem;
        height: 1px;
        background-color: $text;
        content: '';
        position: absolute;
        left: 0;
        top: 1.6rem * 1.3 / 2;
      }

      @media (max-width: 768px){
        font-size: 16px;
        padding-left: 20px;
        margin-bottom: 5px;

        &:before {
          top: 11px;
          width: 13px;
        }
      }
    }
  }
}
.circle-list {
  list-style: none;
  font-size: 1rem;
  padding: 0;
  li {
    padding: 0.7rem 1rem 0;
    &::before {
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 50%;
      background-color: #ED1B2F;
      content: "";
      display: inline-block;
      margin-left: -1rem;
      margin-right: 0.5rem;
    }
  }
  &.blue-ball {
    li {
      &::before {
        background-color: #002E5E;
      }
    }
  }
  &.pink-ball {
    li {
      &::before {
        background-color: #D76285;
      }
    }
  }
}
