
.rate-wrapper {
  font-size: 18px;
  font-style: normal;
  line-height: 32px;
  padding-top: 38px;
  .rate {
    display: flex;
    justify-content: space-around;
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    line-height: 24px;
    &__index {
      color: #828282;
      border-bottom: 2px solid #828282;
      position: relative;
      display: flex;
      justify-content: flex-end;
      &-number {
        padding-bottom: 19px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      &-star {
        height: 16px;
        width: 2px;
        background: #828282;
        position: absolute;
        bottom: -9px;
        right: 3px;
      }
      &.more {
        color: #1C75BC;
        border-bottom: 2px solid #1C75BC;
        .rate__index-star {
          background: #1C75BC;
        }
      }
      &.current {
        .rate__index-star {
          background: url("/pic/star.svg") #002E5E center center no-repeat;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          right: -12px;
          z-index: 1;
          bottom: -16px;
        }
      }
      flex-grow: 1;
      &.first, &.last {
        flex-grow: 0.1;
      }
    }
    &-result {
      color: #002E5E;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      text-transform: uppercase;
    }
  }
  .text {
    padding-bottom: 72px;
  }
  .text-container {
    padding-top: 81px;
    display: flex;
    .header__user-authorized {
      width: 9.6rem!important;
      height: 9.6rem!important;
      margin-right: 2.4rem;
      span {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 37%;
        left: 0;
        width: 9.6rem!important;
        height: 9.6rem!important;
        &:after {
          display: none;
        }
      }
    }
  }
}
