/**
 * lead.scss
 */
.lead {
  display: flex;
  flex-flow: wrap;
  font-weight: 400;

  @media (max-width: 540px){
    .mb-24 {
      margin-bottom: 16px;
    }
  }

  &__image {
    flex: 0 0 12rem;
    max-width: 12rem;
    margin-right: 2.4rem;

    @media (max-width: 768px){
      flex: 0 0 100px;
      max-width: 100px;
      margin-right: 24px;
    }

    @media (max-width: 540px){
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 16px;

      .avatar {
        width: 64px;
        height: 64px;
      }
    }
  }



  &__content {
    flex: 1;

    @media (max-width: 540px){
      flex: 0 0 100%;
      max-width: 100%;
    }

    &-toggle {
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      color: $blue-03;
    }
  }

  @media (max-width: 540px){
    .content_subtitle {
      margin-bottom: 16px;
    }
  }

  &__info {
    flex: 0 0 31%;
    max-width: 31%;
    margin-left: 2.4rem;
    font-size: 1.8rem;

    @media (max-width: 768px){
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: 0;
      padding-left: 124px;
      margin-top: 24px;
      font-size: 16px;
    }

    @media (max-width: 540px){
      padding-left: 0;
      margin-top: 16px;
    }
  }
}