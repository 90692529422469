/**
 * comment
 */
.comment {
  display: flex;
  margin-bottom: 2.4rem;
  padding: 2.4rem;
  background: $white;

  @media (max-width: 768px) {
    margin-bottom: 24px;
    padding: 24px;
  }

  @media (max-width: 540px){
    padding: 16px;
  }

  &__image {
    margin-right: 2.4rem;

    @media (max-width: 768px) {
      margin-right: 24px;
    }

    @media (max-width: 540px){
      margin-right: 16px;

      .avatar_big {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__content {
    flex: 1;
  }

  &__name {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.33;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__date {
    margin: .4rem 0;
    font-size: 1.2rem;
    line-height: 1.33;
    color: $blue-04;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.33;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}