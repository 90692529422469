/**
 * rating
 */
.rating {
  display: inline-flex;
  align-items: center;

  span {
    width: 2.4rem;
    height: 2.4rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.06717 7.29829L12 1.35573L14.9328 7.29829C15.0552 7.54621 15.2917 7.71805 15.5653 7.75781L22.1233 8.71074L17.3779 13.3364C17.1799 13.5294 17.0896 13.8074 17.1363 14.0799L18.2565 20.6114L12.3909 17.5276L12.1117 18.0587L12.3909 17.5276C12.1462 17.399 11.8538 17.399 11.6091 17.5276L11.8883 18.0587L11.6091 17.5276L5.74346 20.6114L6.8637 14.0799C6.91043 13.8074 6.82009 13.5294 6.62211 13.3364L1.8767 8.71074L8.4347 7.75781C8.7083 7.71805 8.94482 7.54621 9.06717 7.29829Z' stroke='%23ED1B2F' stroke-width='1.2'/%3E%3C/svg%3E");

    @media (max-width: 768px) {
      width: 16px;
      height: 16px;
    }

    &.filled {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7848 0.436077C11.8728 0.257698 12.1272 0.257698 12.2152 0.436077L15.4709 7.03275C15.5058 7.10359 15.5734 7.15268 15.6516 7.16404L22.9314 8.22187C23.1283 8.25047 23.2069 8.49238 23.0644 8.63123L17.7967 13.766C17.7401 13.8212 17.7143 13.9006 17.7277 13.9785L18.9712 21.2289C19.0048 21.425 18.7991 21.5745 18.623 21.4819L12.1117 18.0587C12.0418 18.022 11.9582 18.022 11.8883 18.0587L5.37701 21.4819C5.20094 21.5745 4.99516 21.425 5.02878 21.2289L6.27233 13.9785C6.28569 13.9006 6.25987 13.8212 6.20331 13.766L0.93555 8.63123C0.793106 8.49239 0.871708 8.25047 1.06856 8.22187L8.34842 7.16404C8.42659 7.15268 8.49417 7.10359 8.52913 7.03275L11.7848 0.436077Z' fill='%23ED1B2F'/%3E%3C/svg%3E%0A");
    }

    &.semi-filled {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.37709 21.4819L11.9248 18.0396C11.9735 18.014 12.0295 18.0059 12.0835 18.0167L14.5 18.5L15.6841 18.8947C15.8395 18.9465 16 18.8308 16 18.667V7.74C16 7.60745 15.8925 7.5 15.76 7.5H15.6284C15.5482 7.5 15.4733 7.4599 15.4288 7.39313L14.5129 6.01935C14.5043 6.00648 14.497 5.99281 14.4911 5.97853L12.2032 0.487398C12.1239 0.297193 11.8576 0.288712 11.7664 0.473488L8.52921 7.03275C8.49425 7.10359 8.42667 7.15268 8.3485 7.16404L1.06864 8.22187C0.871789 8.25047 0.793186 8.49239 0.93563 8.63123L6.20339 13.766C6.25995 13.8212 6.28577 13.9006 6.27241 13.9785L5.02886 21.2289C4.99524 21.425 5.20102 21.5745 5.37709 21.4819Z' fill='%23ED1B2F'/%3E%3Cpath d='M9.06717 7.29829L12 1.35573L14.9328 7.29829C15.0552 7.54621 15.2917 7.71805 15.5653 7.75781L22.1233 8.71074L17.3779 13.3364C17.1799 13.5294 17.0896 13.8074 17.1363 14.0799L18.2565 20.6114L12.3909 17.5276L12.1117 18.0587L12.3909 17.5276C12.1462 17.399 11.8538 17.399 11.6091 17.5276L11.8883 18.0587L11.6091 17.5276L5.74346 20.6114L6.8637 14.0799C6.91043 13.8074 6.82009 13.5294 6.62211 13.3364L1.8767 8.71074L8.4347 7.75781C8.7083 7.71805 8.94482 7.54621 9.06717 7.29829Z' stroke='%23ED1B2F' stroke-width='1.2'/%3E%3C/svg%3E");
    }

    + span {
      margin-left: 0.8rem;
    }
  }

  &__feedbacks {
    font-size: 1.8rem;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    a {
      color: $red;
      text-decoration: underline;
      transition: .3s ease-in-out;

      &:hover {
        color: $red-hover;
      }
    }
  }

  &__data {
    color: $red;
    font-size: 1.8rem;

    @media (max-width: 768px) {
      font-size: 16px
    }
  }
}