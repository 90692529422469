/**
 * preview-video.scss
 */
.preview-video {
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .arrow-button {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s ease-in-out;
    width: 4.8rem;
    height: 4.8rem;
    cursor: pointer;
    border-radius: 50%;
    background-color: $red;
    background-position: 50% 50%;
    background-size: 4.8rem auto;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='%23ED1B2F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='white'/%3E%3C/svg%3E");

    &:hover {
      width: 10rem;
      height: 10rem;
      background-size: 2rem auto;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 9L1 1V17L16 9Z' stroke='white' stroke-width='2' stroke-linejoin='bevel'/%3E%3C/svg%3E");
      background-color: $red-hover;
    }

    @media (max-width: 540px){
      width: 48px;
      height: 48px;
      background-size: 48px auto;

      &:hover {
        width: 100px;
        height: 100px;
        background-size: 20px auto;
      }
    }
  }
}