/**
 * link target
 */
.link-target {
  display: inline-flex;
  align-items: center;
  font-size: 1.8rem;
  color: $blue-03;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  padding-left: 2.2rem;
  margin-left: -2.2rem;
  padding-right: 0;
  padding-top: 0;
  border-radius: 0 2.4rem 2.4rem 0;
  transition: background-color 0.3s, color 0.35s ;
  border: 0;
  background-color: transparent;
  position: relative;
  white-space: nowrap;

  @media (max-width: 768px){
    font-size: 14px;
    white-space: nowrap;
    line-height: 1;
    margin-left: 0 !important;
    padding-left: 12px !important;
  }

  &:active,
  &:focus {
    outline: 0;
  }

  &:after {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    color: $secondary;
    content: '';
    margin-left: 1.4rem;
    transition: 0.3s ease-in-out;
    background: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='%23ED1B2F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='white'/%3E%3C/svg%3E") no-repeat 50% 50% / contain;
    position: relative;

    @media (max-width: 768px){
      width: 32px;
      height: 32px;
    }
  }

  span {
    position: relative;
  }

  &:before {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 2.4rem;
    background-color: $secondary;
    position: absolute;
    transition: .3s ease-in-out;
    right: 0;
    content: '';
    top: 0;

    @media (max-width: 768px){
      width: 100%;
      background-color: #E0E0E0;
      height: 32px;
      border-radius: 16px;
    }

    .section__footer & {
      @media (max-width: 768px){
        background-color: $blue-02;
      }
    }
  }

  &:hover {
    text-decoration: none;
    outline: none;

    @media (min-width: 769px){
      color: $white;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='%23C61929'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='white'/%3E%3C/svg%3E");
      }

      &:before {
        width: 100%;
      }
    }

    @media (max-width: 768px){
      &:before {
        width: 100%;
        background-color: #E0E0E0;
      }
    }
  }

  // round button
  &_button {
    padding-left: 0;
    border-radius: 50%;

    &:after {
      margin: 0;
    }
  }

  &_sm {
    display: inline-flex;
    align-items: center;
    text-decoration: underline;
    color: $text;

    &:after {
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      content: '';
      margin-left: 1rem;
      transition: 0.3s ease-in-out;
      background: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='%23ED1B2F'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='white'/%3E%3C/svg%3E") no-repeat 50% 50% / contain;
      position: relative;

      @media (max-width: 768px){
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='23' fill='%23C61929'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7366 11H35V34.2634H32.9167V14.5564L12.4731 35L11 33.5269L31.4436 13.0833H11.7366V11Z' fill='white'/%3E%3C/svg%3E");
      }
    }
  }
}
.link-underline {
  font-size: 1.8rem;
  color: $blue-03;
  line-height: 2.4rem;
  text-decoration: underline;
  @media (max-width: 768px){
    font-size: 16px;
    display: none;
  }
  &.text-red {
    color: #ED1B2F!important;
  }
  &:hover {
    text-decoration: none;
  }
}
