/**
 * main-menu.scss
 */
.main-menu {
  .header {
    position: absolute;
    background: $white;
  }
}

.crisis {
  text-decoration: none;
  color: $red!important;
}
.header__navigation {
  .crisis {
    &:hover {
      color: #fff!important;
    }
  }
}

.section__menu {
  transition: transform .9s cubic-bezier(0.65, 0.05, 0.36, 1);
  position: fixed;
  //background: $body-bg;
  background: $white;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  display: flex;
  justify-content: stretch;
  padding-bottom: 3rem;


  .show-mainmenu & {
    transform: translateX(0);
  }

  .section__wrapper {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
      padding-top: 220px;
      transition: .3s ease-in-out;
    }

    .show-submenu & {
      @media (max-width: 768px) {
        padding-top: 126px;
      }
      @media (max-width: 540px) {
        padding-top: 102px;
      }
    }
  }

  @media (max-width: 768px) {
    &-content {
      position: relative;
      flex: 1;
    }
  }

  &-menu {
    flex: 1 0 auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      position: relative;
    }

    li {
      margin: 0;
      padding: 0;
      max-width: 55rem;

      @media (max-width: 768px) {
        max-width: 550px;

        &.placeholder {
          display: none;
        }

        &.mb-md-6 {
          margin-bottom: 0;

          a {
            margin-bottom: 45px;
          }
        }
      }

      &.placeholder {
        position: absolute;
        height: 4.8rem;
        top: 0;
        background: $red no-repeat calc(100% - 1.5rem) 50% url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
        background-size: 2.4rem auto;
        width: 55rem;
        border-radius: 0 4.8rem 4.8rem 0;
        transition: .5s ease-in-out;
      }
    }

    a {
      display: block;
      padding: 0 4rem 0 1.5rem;
      height: 4.8rem;
      line-height: 4.8rem;
      font-size: 2.4rem;
      color: $text;
      border-radius: 0 4.8rem 4.8rem 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      z-index: 2;
      position: relative;
      transition: .2s ease-in-out;

      @media (max-width: 768px) {
        height: 32px;
        font-size: 16px;
        line-height: 32px;
        padding: 0;
        margin-bottom: 8px;
        transition: none;
        animation-name: animation-submenu-pad;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-duration: 0.2s;
      }

      .show-submenu & {
        @media (max-width: 768px) {
          &:not(.active) {
            display: none;
          }
        }
      }

      &:hover, &.crisis {
        text-decoration: none;
        color: $red;

        @media (max-width: 768px) {
          color: $text;
        }
      }

      &.active {
        color: $white;

        @media (max-width: 768px) {
          background-color: $red;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.92 0.62C12.8185 0.375651 12.6243 0.181475 12.38 0.0799999C12.2598 0.028759 12.1307 0.00157999 12 0H2C1.73478 0 1.48043 0.105357 1.29289 0.292893C1.10536 0.48043 1 0.734784 1 1C1 1.26522 1.10536 1.51957 1.29289 1.70711C1.48043 1.89464 1.73478 2 2 2H9.59L1.29 10.29C1.19627 10.383 1.12188 10.4936 1.07111 10.6154C1.02034 10.7373 0.994202 10.868 0.994202 11C0.994202 11.132 1.02034 11.2627 1.07111 11.3846C1.12188 11.5064 1.19627 11.617 1.29 11.71C1.38296 11.8037 1.49356 11.8781 1.61542 11.9289C1.73728 11.9797 1.86799 12.0058 2 12.0058C2.13201 12.0058 2.26272 11.9797 2.38458 11.9289C2.50644 11.8781 2.61704 11.8037 2.71 11.71L11 3.41V11C11 11.2652 11.1054 11.5196 11.2929 11.7071C11.4804 11.8946 11.7348 12 12 12C12.2652 12 12.5196 11.8946 12.7071 11.7071C12.8946 11.5196 13 11.2652 13 11V1C12.9984 0.869323 12.9712 0.740222 12.92 0.62Z' fill='%23ffffff'/%3E%3C/svg%3E%0A"),
          url("data:image/svg+xml,%3Csvg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9999 6.00019H3.4099L6.7099 2.71019C6.8982 2.52188 7.00399 2.26649 7.00399 2.00019C7.00399 1.73388 6.8982 1.47849 6.7099 1.29019C6.52159 1.10188 6.2662 0.996094 5.9999 0.996094C5.73359 0.996094 5.4782 1.10188 5.2899 1.29019L0.289896 6.29019C0.198856 6.38529 0.127491 6.49743 0.0798963 6.62019C-0.0201217 6.86365 -0.0201217 7.13672 0.0798963 7.38019C0.127491 7.50294 0.198856 7.61508 0.289896 7.71019L5.2899 12.7102C5.38286 12.8039 5.49346 12.8783 5.61532 12.9291C5.73718 12.9798 5.86788 13.006 5.9999 13.006C6.13191 13.006 6.26261 12.9798 6.38447 12.9291C6.50633 12.8783 6.61693 12.8039 6.7099 12.7102C6.80363 12.6172 6.87802 12.5066 6.92879 12.3848C6.97956 12.2629 7.00569 12.1322 7.00569 12.0002C7.00569 11.8682 6.97956 11.7375 6.92879 11.6156C6.87802 11.4937 6.80363 11.3831 6.7099 11.2902L3.4099 8.00019H10.9999C11.2651 8.00019 11.5195 7.89483 11.707 7.70729C11.8945 7.51976 11.9999 7.2654 11.9999 7.00019C11.9999 6.73497 11.8945 6.48062 11.707 6.29308C11.5195 6.10554 11.2651 6.00019 10.9999 6.00019Z' fill='white'/%3E%3C/svg%3E");
          background-position: calc(100% - 16px) 50%, 42px 50%;
          background-size: 16px auto, 12px auto;
          border-radius: 0 24px 24px 0;
          padding-left: 71px;
          height: 48px;
          line-height: 48px;
        }
        @media (max-width: 540px){
          padding-left: 56px;
          background-position: calc(100% - 16px) 50%, 22px 50%;
        }
      }
    }
  }

  &-submenu {
    overflow: hidden;

    @media (max-width: 768px) {
      position: absolute;
      top: 84px;
      left: 0;
      padding-left: 71px;
      right: 20px;
      bottom: 0;
      width: auto;
    }

    @media (max-width: 540px) {
      padding-left: 56px;

      .title-with-icon__title {
        font-size: 14px;
      }
      .section__menu-submenu-secondary a {
        font-size: 13px;
      }
    }
  }

  .submenu {
    transition: transform .9s ease-in-out, opacity .5s ease-in-out;

    &:not(.active) {
      opacity: 0;
      transform: translateX(100%);
      position: absolute;
      top: 0;
      left: 0;

      @media (max-width: 768px) {
        transform: translateX(-100%);
      }
    }
  }

  &-submenu-secondary {
    ul {
      margin: 0 0 3.2rem 6.4rem;
      padding: 0;
      list-style: none;

      @media (max-width: 768px) {
        margin: 0 0 32px 42px;
      }
    }

    li {
      margin: 0 0 1.2rem;

      @media (max-width: 768px) {
        margin: 0 0 12px;
      }
    }

    a {
      font-size: 1.8rem;

      @media (max-width: 768px) {
        font-size: 16px;
      }
      color: $text;
    }
  }

  &-footer {
    margin-left: 1.5rem;
    font-size: 1.4rem;
    color: $text;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-left: 0;
    }
    @media (max-width: 540px) {
      font-size: 13px;
    }
  }

  .show-submenu & {
    @media (max-width: 768px){
      .header__user {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    .header__user {
      position: absolute;
      right: 15px;
      top: 83px;
      width: 122px;
      display: block;

      @media (max-width: 540px){
        top: 69px;
        width: 105px;
      }

      a {
        text-indent: 0;
        padding-left: 36px;
        font-size: 15px;

        @media (max-width: 540px){
          padding-left: 26px;
          font-size: 13px;
        }
      }

      &-login a {
        padding-left: 36px;
        background-size: 20px auto;

        @media (max-width: 540px){
          background-size: auto 13px;
          padding-left: 25px;
        }
      }

      &-registration {
        margin-top: 24px;

        a {
          background-size: 16px auto;
          background-position: 3px 50%;

          @media (max-width: 540px){
            background-size: auto 13px;
            background-position: 0 50%;
            padding-left: 25px;
          }
        }
      }
    }
  }


}

@keyframes animation-submenu {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(-100%);
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes animation-submenu-pad {
  0% {
    display: none;
    opacity: 0;
  }
  50% {
    display: none;
    opacity: 0;
  }
  70% {
    display: none;
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    display: block;
    opacity: 1;
  }
}