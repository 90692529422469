/**
 * calendar
 */
.section__calendar {
  align-items: flex-start;

  @media screen and (min-width: 1731px) {
    padding-top: 21.3rem;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 1.3;
    font-weight: 700;

    a {
      color: $blue-03;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 768px) {
    &-row {
      .col-md-4 {
        order: -1;
      }
    }

    .btn-socials {
      margin-bottom: 30px !important;
    }
  }

  &-content {

    @media (min-width: 1250px) {
      margin-right: 9rem;
    }
  }

  &-description {
    font-size: 1.8rem;
    line-height: 1.7;

    a {
      color: $red;
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 1.5;
      margin-top: 39px;
    }

    @media (max-width: 540px) {
      margin-top: 0;
      line-height: 2;
      margin-bottom: 15px;
    }
  }

  &-search {
    position: relative;

    input {
      display: block;
      width: 100%;
      font-size: 1.8rem;
      border-width: 0 0 3px 0;
      border-color: $blue-03;
      padding: 2rem 9rem 2.4rem 7.2rem;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: 2.6rem 50%;
      outline: none;
      background-size: 2rem auto;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.7192 18.3139L16.0114 14.6333C17.4506 12.8374 18.1476 10.5579 17.959 8.26356C17.7705 5.96919 16.7106 3.83432 14.9975 2.29792C13.2844 0.761532 11.0481 -0.0595993 8.74862 0.00337152C6.44911 0.0663423 4.26109 1.00863 2.63448 2.63648C1.00786 4.26433 0.066292 6.454 0.00336896 8.75527C-0.059554 11.0565 0.760954 13.2945 2.29618 15.0089C3.83141 16.7233 5.96466 17.784 8.25729 17.9727C10.5499 18.1614 12.8277 17.4639 14.6222 16.0235L18.3 19.7042C18.3929 19.7979 18.5035 19.8723 18.6253 19.9231C18.747 19.9739 18.8777 20 19.0096 20C19.1415 20 19.2722 19.9739 19.3939 19.9231C19.5157 19.8723 19.6263 19.7979 19.7192 19.7042C19.8993 19.5177 20 19.2684 20 19.009C20 18.7497 19.8993 18.5004 19.7192 18.3139ZM9.01554 16.0235C7.63189 16.0235 6.27932 15.6129 5.12886 14.8436C3.9784 14.0743 3.08172 12.9809 2.55223 11.7016C2.02273 10.4223 1.88419 9.01462 2.15412 7.65653C2.42406 6.29844 3.09035 5.05095 4.06873 4.07183C5.04712 3.0927 6.29366 2.4259 7.65072 2.15576C9.00778 1.88562 10.4144 2.02426 11.6927 2.55417C12.9711 3.08407 14.0637 3.98142 14.8324 5.13276C15.6011 6.28409 16.0114 7.63769 16.0114 9.02239C16.0114 10.8792 15.2743 12.66 13.9623 13.973C12.6504 15.2859 10.871 16.0235 9.01554 16.0235Z' fill='%23002E5E'/%3E%3C/svg%3E ");

      @media (max-width: 768px) {
        font-size: 14px;
        background-size: 20px auto;
        padding-left: 38px;
        background-position: 0 45%;
      }
    }

    &-actions {
      position: absolute;
      right: 2.4rem;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      opacity: 0;
      transition: .3s ease-in-out;
    }

    &.active &-actions {
      opacity: 1;
    }

    .btn {
      display: flex;
      align-items: center;
      border-radius: 2.6rem;
      padding: .7rem 2rem;
      height: 4.1rem;

      span {
        width: .7rem;
        height: .6rem;
        border-radius: 50%;
        margin-right: .5rem;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='6' viewBox='0 0 7 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.092 2.83338C6.07116 2.77964 6.03991 2.73054 6.00006 2.68891L3.811 0.49985C3.77018 0.459029 3.72172 0.426649 3.66839 0.404557C3.61505 0.382465 3.55789 0.371094 3.50016 0.371094C3.38357 0.371094 3.27175 0.417409 3.18931 0.49985C3.14849 0.540671 3.11611 0.589133 3.09402 0.642468C3.07192 0.695803 3.06055 0.752967 3.06055 0.810696C3.06055 0.927286 3.10687 1.0391 3.18931 1.12154L4.63409 2.56194H1.3111C1.19499 2.56194 1.08363 2.60807 1.00152 2.69017C0.919417 2.77228 0.873291 2.88364 0.873291 2.99975C0.873291 3.11587 0.919417 3.22722 1.00152 3.30933C1.08363 3.39144 1.19499 3.43756 1.3111 3.43756H4.63409L3.18931 4.87796C3.14828 4.91866 3.1157 4.96708 3.09348 5.02043C3.07125 5.07378 3.05981 5.13101 3.05981 5.18881C3.05981 5.2466 3.07125 5.30383 3.09348 5.35718C3.1157 5.41053 3.14828 5.45895 3.18931 5.49965C3.23001 5.54069 3.27843 5.57326 3.33178 5.59548C3.38514 5.61771 3.44236 5.62915 3.50016 5.62915C3.55795 5.62915 3.61518 5.61771 3.66853 5.59548C3.72188 5.57326 3.7703 5.54069 3.811 5.49965L6.00006 3.3106C6.03991 3.26896 6.07116 3.21986 6.092 3.16612C6.13579 3.05953 6.13579 2.93997 6.092 2.83338Z' fill='white'/%3E%3C/svg%3E");
      }
    }

    .close {
      cursor: pointer;
      width: 1.3rem;
      height: 1.3rem;
      display: block;
      margin-right: 2rem;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.17511 7.00015L13.4251 1.75849C13.582 1.60157 13.6702 1.38874 13.6702 1.16682C13.6702 0.944903 13.582 0.732074 13.4251 0.575155C13.2682 0.418235 13.0554 0.330078 12.8334 0.330078C12.6115 0.330078 12.3987 0.418235 12.2418 0.575155L7.00011 5.82515L1.75845 0.575155C1.60153 0.418235 1.3887 0.330078 1.16678 0.330078C0.944861 0.330078 0.732032 0.418235 0.575112 0.575155C0.418192 0.732074 0.330036 0.944903 0.330036 1.16682C0.330036 1.38874 0.418192 1.60157 0.575112 1.75849L5.82511 7.00015L0.575112 12.2418C0.497005 12.3193 0.43501 12.4115 0.392703 12.513C0.350395 12.6146 0.328613 12.7235 0.328613 12.8335C0.328613 12.9435 0.350395 13.0524 0.392703 13.154C0.43501 13.2555 0.497005 13.3477 0.575112 13.4252C0.652581 13.5033 0.744749 13.5653 0.846298 13.6076C0.947847 13.6499 1.05677 13.6717 1.16678 13.6717C1.27679 13.6717 1.38571 13.6499 1.48726 13.6076C1.58881 13.5653 1.68098 13.5033 1.75845 13.4252L7.00011 8.17515L12.2418 13.4252C12.3193 13.5033 12.4114 13.5653 12.513 13.6076C12.6145 13.6499 12.7234 13.6717 12.8334 13.6717C12.9435 13.6717 13.0524 13.6499 13.1539 13.6076C13.2555 13.5653 13.3476 13.5033 13.4251 13.4252C13.5032 13.3477 13.5652 13.2555 13.6075 13.154C13.6498 13.0524 13.6716 12.9435 13.6716 12.8335C13.6716 12.7235 13.6498 12.6146 13.6075 12.513C13.5652 12.4115 13.5032 12.3193 13.4251 12.2418L8.17511 7.00015Z' fill='%23ED1B2F'/%3E%3C/svg%3E");
      background-size: contain;
    }
  }

  &-calendar {
    padding: 2.4rem;
    background-color: $white;

    @media (max-width: 768px) {
      margin-bottom: 40px !important;
      display: flex;
      padding: 24px;
    }

    @media (max-width: 540px) {
      flex-flow: wrap;
      padding: 16px;
      margin-bottom: 32px !important;
    }
  }

  &-filter {

    @media (max-width: 768px) {
      flex-grow: 1;
      padding: 24px;
      background: $grey-06;
      margin-top: 48px;

      h2 {
        font-size: 16px;
        margin-bottom: 15px !important;
      }
    }

    @media (max-width: 540px) {
      margin-top: 15px;

      .checkbox {
        margin-top: 20px;

        input + label {
          font-size: 14px;
        }
      }

    }

    select {
      display: block;
      appearance: none;
      width: 100%;
      border: 0;
      background-color: $grey-06;
      background-position: calc(100% - 2rem) 50%;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.99995 4.71018L2.45995 1.17018C2.27259 0.983927 2.01913 0.879386 1.75495 0.879386C1.49076 0.879386 1.23731 0.983927 1.04995 1.17018C0.95622 1.26314 0.881825 1.37374 0.831057 1.4956C0.780288 1.61746 0.75415 1.74817 0.75415 1.88018C0.75415 2.01219 0.780288 2.1429 0.831057 2.26476C0.881826 2.38661 0.95622 2.49722 1.04995 2.59018L5.28995 6.83018C5.38291 6.92391 5.49351 6.9983 5.61537 7.04907C5.73723 7.09984 5.86794 7.12598 5.99995 7.12598C6.13196 7.12598 6.26267 7.09984 6.38453 7.04907C6.50638 6.9983 6.61699 6.92391 6.70995 6.83018L10.9999 2.59018C11.0926 2.49674 11.166 2.38592 11.2157 2.26408C11.2655 2.14225 11.2907 2.01178 11.2899 1.88018C11.2907 1.74857 11.2655 1.61811 11.2157 1.49627C11.166 1.37443 11.0926 1.26362 10.9999 1.17018C10.8126 0.983927 10.5591 0.879386 10.2949 0.879386C10.0308 0.879386 9.77731 0.983927 9.58995 1.17018L5.99995 4.71018Z' fill='%231C75BC'/%3E%3C/svg%3E");
      outline: 0;
      padding: 1.2rem 3rem 1.2rem 1.4rem;
      color: $grey-03;
      font-size: 1.8rem;

      @media (max-width: 768px) {
        font-size: 14px;
        background-color: $white;
        padding: 12px 40px 12px 14px;
      }
    }
  }

  &-course {
    display: block;
    padding-bottom: 3rem;
    margin-bottom: 5rem;
    border-bottom: 1px solid $grey-04;
    transition: .3s ease-in-out;

    @media (max-width: 768px) {
      flex: 0 0 calc(50% - 24px);
      max-width: calc(50% - 24px);
      padding: 0 0 24px;
      margin: 0 12px 65px;
    }

    @media (max-width: 540px) {
      flex: 0 0 calc(100% - 24px);
      max-width: calc(100% - 24px);
      padding: 0 0 24px;
      margin: 0 12px 55px;
    }

    span {
      display: block;
    }

    &-target {
      margin-bottom: 1rem;

      @media (max-width: 768px) {
        margin-bottom: 12px;
      }
    }

    &-title {
      font-size: 2.4rem;
      line-height: 1.1;
      margin-bottom: 1.2rem;
      color: $text;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      @media (max-width: 540px) {
        margin-bottom: 12px;
      }
    }

    &-views {
      font-size: 1.8rem;
      color: $red;
      text-decoration: underline;

      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }

  &-table {
    @media (max-width: 768px) {
      margin: 0 24px 0 0;
      flex: 0 0 320px;
    }
  }

  &-courses {
    @media (max-width: 768px) {
      display: flex;
      margin: 0 -12px;
      flex-flow: wrap;
    }


    &-wrapper {
      h2 {
        margin-top: 50px;
        margin-bottom: 35px !important;
        font-size: 24px;
      }
    }
  }
}